export const USER_TAB_COOKIE = 'U_TAB';
export const TAB_NAME = {
    BUY: 'BUY',
    SELL: 'SELL',
    FINANCE: 'FINANCE',
    INITIAL: ''
};
export const CLEARER_LISTING_PAGE_ENTRY_POINT_EXP = {
    olxmx: 'ROAD-30118',
    olxcl: 'ROAD-30111'
};
