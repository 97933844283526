import React from 'react';

import loadable from '@loadable/component';

const ValuationFlowV2 = loadable(() => import(/* webpackChunkName: "valuation-flow-v2" */'./ValuationFlowV2').then(module => module.default));

export const ValuationFlow = ({ ...props }) => {
    return <ValuationFlowV2 { ...props } />;
};

export default ValuationFlow;
