import { convertToAbsoluteImageURI } from 'Helpers/images';
import { SVG_EXT, X } from 'Constants/images';

/**
 * It is called dynamically from buildSchema()
 * @param props
 * @returns {{ '@context': string, '@graph': [{ '@type': 'WebSite', ...otherValues }, { '@type': 'Organization', ...otherValues }] }}
 */
const buildWebSite = props => {
    const { marketConfig } = props;
    const schemaConfig = marketConfig.get('schema');
    const hostUrl = schemaConfig.url;
    const businessType = marketConfig.get('isOlxMobbi') ? 'olxmobbi' : 'olx';

    const types = {
        slug: {
            searchUrl: {
                olx: `${hostUrl}/items/q-{search_term_string}`,
                olxmobbi: `${hostUrl}/mobil-bekas_c198/q-{search_term_string}`
            }
        }
    };

    const urlType = marketConfig.get('SEO', 'sitelinksSearchStructuredData');
    const searchUrl = types[urlType]?.searchUrl?.[businessType] || types.slug.searchUrl[businessType];

    const staticAsseturl = marketConfig.get('staticAssets');
    const brandLogo = schemaConfig.logo;

    if (!(schemaConfig && hostUrl && staticAsseturl && brandLogo)) {
        throw new Error('Market config is not available');
    }

    return {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'WebSite',
                url: hostUrl,
                name: schemaConfig.name,
                description: schemaConfig.description,
                potentialAction: {
                    '@type': 'SearchAction',
                    target: searchUrl,
                    'query-input': 'required name=search_term_string'
                }
            },
            {
                '@type': 'Organization',
                name: schemaConfig.name,
                url: hostUrl,
                logo: convertToAbsoluteImageURI(brandLogo, SVG_EXT, staticAsseturl, X),
                sameAs: schemaConfig.sameAs,
                contactPoint: {
                    '@type': 'ContactPoint',
                    ...schemaConfig.contactPoint
                },
                address: {
                    '@type': 'PostalAddress',
                    ...schemaConfig.address
                }
            }
        ]
    };
};

export default buildWebSite;
