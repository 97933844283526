export const STEPS = {
    main: '#main',
    sendmessage: '#sendmessage',
    resetpassword: '#resetpassword',
    loginsmscode: '#loginsmscode',
    loginemailcode: '#loginemailcode',
    loginsmsenterpassword: '#loginsmsenterpassword',
    loginemailenterpassword: '#loginemailenterpassword',
    notificationpermission: '#notificationpermission',
    termsAndConditions: '#termsAndConditions',
    loginemail: '#loginemail',
    loginsms: '#loginsms',
    loginemailcreatepassword: '#loginemailcreatepassword',
    loginsmscreatepassword: '#loginsmscreatepassword',
    verifysms: '#verifysms',
    recoversmspasswordcode: '#recoversmspasswordcode',
    accountinuse: '#accountinuse',
    verifysmscode: '#verifysmscode',
    consents: '#consents'
};

export const ACTIONS = {
    PIN: 'PIN',
    PASSWORD: 'PASSWORD'
};

export const LOGIN_GRANT_TYPE = {
    phone: 'phone',
    email: 'email',
    password: 'password',
    pin: 'pin',
    retry: 'retry',
    recover: 'recover',
    forcePhonePin: 'phone-force-pin'
};

export const LOGIN_METHOD = {
    phone: 'phone',
    email: 'email',
    sms: 'sms',
    call: 'call'
};

export const VERIFICATION_METHOD = {
    login: 'login',
    linkaccount: 'linkaccount',
    recoversmspassword: 'recoversmspassword'
};

export const STATUS = {
    PENDING: 'PENDING',
    GRANTED: 'GRANTED'
};

