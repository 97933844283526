import { createSelector } from 'reselect';

const getLocationParameters = (addComp = []) => {
    const locationObj = {};

    addComp?.forEach(ele => {
        if (ele.type === 'CITY' || ele.type === 'STATE' || ele.type === 'NEIGHBOURHOOD') {
            locationObj[`${ele.type.toLowerCase()}_id`] = ele.id;
            locationObj[`${ele.type.toLowerCase()}_name`] = ele.name;
        }
    });

    return locationObj;
};

export const getSelectedLocationSelector = createSelector(
    selectedLocation => selectedLocation,
    ({ addressComponents }) => getLocationParameters(addressComponents)
);
