import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { setItem as setSessionItem, getItem as getSessionItem } from 'Helpers/sessionStorage';
import { useLeadGeneration } from 'Hooks/useLeadGeneration/useLeadGeneration';
import { LEAD_GEN_SOURCES } from 'Constants/items';
import { EXPONEA_EVENT_TO_KEY_MAP } from 'Constants/exponeaLeads';
import UserDetailsFormUI from './UserDetailsFormUI';
import { compose } from 'redux';
import {
    OLXAUTOS
} from 'Constants/tracking';
import { EvaluationConstants, LayoutSelector, LayoutHelper } from 'olx-autos-landing-page';
import CookieManager from 'Helpers/cookies';
import { connect } from 'react-redux';

export const UserDetailsFormWidget = ({
    css,
    intl,
    config,
    dealer,
    item,
    userFlow,
    eventType,
    hp_tracking_event,
    thankYouTitle,
    thankYouSubtitle,
    eventOrigin,
    onSetThankYou,
    onSubmitComplete,
    trackingOrigin,
    track,
    ...restProps
}) => {
    const itemLeadMap = useRef({});

    const getTrackingInfo = useCallback(rest => {
        const defaultProperty = {
            origin: trackingOrigin,
            user_type: CookieManager.readCookie(EvaluationConstants.REPEAT_USER_COOKIE_NAME) ? 2 : 1,
            ...LayoutHelper.getAcquisitionChannelTrackingValues(),
            ...rest
        };

        return defaultProperty;
    }, [trackingOrigin]);

    const handleAttributeValueChange = useCallback(trackingData => {
        if (track) {
            track(OLXAUTOS.OLXAUTOS_FORM_FIELD_BLUR, { ...trackingData, ...getTrackingInfo() });
        }
    }, [track, getTrackingInfo]);

    const onLeadSubmitComplete = useCallback(({ params, uid }) => {
        if (item && item.id) {
            itemLeadMap.current[item.id] = true;
        }
        else {
            itemLeadMap.current[eventOrigin] = true;
        }
        setSessionItem(EXPONEA_EVENT_TO_KEY_MAP[eventType], JSON.stringify(itemLeadMap.current));

        if (track && hp_tracking_event) {
            const trackProps = getTrackingInfo({
                user_id: uid,
                select_from: hp_tracking_event,
                flow_type: LEAD_GEN_SOURCES.EXCHANGE_CAR
            });

            track(OLXAUTOS.OLXAUTOS_LEAD_FORM_SUBMIT, trackProps);
        }

        onSubmitComplete({ params, uid });
    }, [eventOrigin, eventType, item, onSubmitComplete, getTrackingInfo, hp_tracking_event, track]);

    const isAlreadySubmitted = useMemo(() => {
        let itemToLeadsMapping = getSessionItem(EXPONEA_EVENT_TO_KEY_MAP[eventType]);

        itemToLeadsMapping = (itemToLeadsMapping) ? JSON.parse(itemToLeadsMapping) : {};
        itemLeadMap.current = itemToLeadsMapping;

        const sessionKey = item && item.id ? item.id : eventOrigin;

        return sessionKey in itemToLeadsMapping;
    }, [eventOrigin, eventType, item]);

    const [
        [name, setName],
        [, setEmail],
        [, setPhone],
        areAllUserValuesValid,
        isPosting,
        isCompleted,
        onLeadSubmit,
        isErr
    ] = useLeadGeneration({
        eventName: eventType,
        item,
        dealer,
        userFlow,
        siteCode: config.get('siteCode'),
        onComplete: onLeadSubmitComplete,
        source: LEAD_GEN_SOURCES.SHOW_INTEREST,
        track,
        getTrackingInfo,
        hp_tracking_event
    });
    const showTnCCheckBox = true;
    const [tncAccepted, toggleTnc] = useState(!showTnCCheckBox);

    const showThankYou = isCompleted || isAlreadySubmitted;

    const onToggleTnC = useCallback(() => {
        toggleTnc(currentValue => !currentValue);
    }, []);

    useEffect(() => {
        if (showThankYou) {
            onSetThankYou();
        }
    }, [showThankYou, onSetThankYou]);

    return (
        <UserDetailsFormUI
            css={ css }
            showThankYou={ showThankYou }
            onLeadSubmit={ onLeadSubmit }
            intl={ intl }
            name={ name }
            setName={ setName }
            isPosting={ isPosting }
            setEmail={ setEmail }
            areAllUserValuesValid={ areAllUserValuesValid }
            config={ config }
            setPhone={ setPhone }
            isErr={ isErr }
            thankYouTitle={ thankYouTitle }
            thankYouSubtitle={ thankYouSubtitle }
            showSubHeading
            showTnCCheckBox={ showTnCCheckBox }
            tncAccepted={ tncAccepted }
            onToggleTnC={ onToggleTnC }
            showTAndC={ !showTnCCheckBox }
            handleFieldChange={ handleAttributeValueChange }
            hp_tracking_event={ hp_tracking_event }
            { ...restProps }
        />
    );
};

UserDetailsFormWidget.propTypes = {
    css: PropTypes.shape({}),
    intl: PropTypes.shape({
        formatMessage: PropTypes.func
    }).isRequired,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    item: PropTypes.object,
    dealer: PropTypes.object,
    userFlow: PropTypes.string,
    eventType: PropTypes.string,
    hp_tracking_event: PropTypes.string,
    thankYouSubtitle: PropTypes.string,
    thankYouTitle: PropTypes.string,
    eventOrigin: PropTypes.string,
    onSetThankYou: PropTypes.func.isRequired,
    onSubmitComplete: PropTypes.func,
    track: PropTypes.func,
    trackingOrigin: PropTypes.string
};

UserDetailsFormWidget.defaultProps = {
    item: {},
    onSubmitComplete: () => {},
    onSetThankYou: () => {}
};

export const mapStateToProps = (state, ownProps) => {
    return {
        trackingOrigin: LayoutSelector.getTrackingOrigin(state, ownProps.location, ownProps.cofig)
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps)
)(UserDetailsFormWidget);
