import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from './SEO';

import { withConfig } from 'HOCs/withConfig/withConfig';

export const SocialMetaTags = ({
    title,
    description,
    image,
    path,
    ogType,
    twitterCard,
    marketConfig
}) => {
    const seoConfig = marketConfig.get('SEO');
    const socialMeta = marketConfig.get('socialMeta');
    const hostPath = marketConfig.get('host');
    const defaultTitle = seoConfig && seoConfig.defaultTitle || '';
    const titleTemplate = seoConfig && seoConfig.titleTemplate || '';
    const defaultDescription = seoConfig && seoConfig.defaultDescription || '';
    const fbAdmin = socialMeta && socialMeta.facebookNumericId || '';
    const ogSiteName = socialMeta && socialMeta.siteName || '';
    const twitterSite = socialMeta && socialMeta.twitterPublisher || '';

    const tags = [
        // [title (string), description (string), ogTag (boolean)]
        ['og:title', (title.length) ? title : defaultTitle, true],
        ['og:description', (description?.length) ? description : defaultDescription, true],
        ['og:url', hostPath + path, true],
        ['og:image', image, true],
        ['og:site_name', ogSiteName || titleTemplate, true],
        ['fb:admins', fbAdmin, true],
        ['og:type', ogType, true],
        ['twitter:card', twitterCard, false],
        ['twitter:site', twitterSite, false]
    ];

    return (
        <React.Fragment>
            {
                tags.map(tag => {
                    return (
                        <SEO
                            key={ tag[0] }
                            tagName={ tag[0] }
                            description={ tag[1] }
                            ogTag={ tag[2] }
                            titleTemplate={ titleTemplate }
                        />
                    );
                })
            }
        </React.Fragment>
    );
};

SocialMetaTags.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,

    // open graph //
    ogType: PropTypes.string,

    // twitter card //
    twitterCard: PropTypes.string
};

SocialMetaTags.defaultProps = {
    title: '',
    description: '',
    image: '',
    path: '',

    // open graph //
    ogType: 'website',

    // twitter card //
    twitterCard: 'summary'
};

export default withConfig('marketConfig')(SocialMetaTags);
