import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import buildProduct from 'Components/SEO/Microdata/Schemas/ProductSchema';
import buildWebSite from 'Components/SEO/Microdata/Schemas/WebsiteSchema';
import buildFAQPage from 'Components/SEO/Microdata/Schemas/FAQSchema';

const schemasAllowed = ['WebSite', 'Product', 'BreadcrumbList', 'FAQPage'];
const componentToSchema = {
    Home: 'WebSite',
    Item: 'Product',
    Breadcrumb: 'BreadcrumbList',
    FAQPage: 'FAQPage'
};
const JsonLDSchema = ({ type = 'WebSite', ...otherProps }) => {
    const buildSchema = (type, otherProps) => {
        if (type in componentToSchema) {
            type = componentToSchema[type];
        }
        if (!schemasAllowed.includes(type)) {
            throw new Error(`${type} is not a valid schema type`);
        }

        switch (type) {
            case 'WebSite':
                return buildWebSite(otherProps);
            case 'Product':
                return buildProduct(otherProps);
            case 'BreadcrumbList':
                break;
            case 'FAQPage':
                return buildFAQPage(otherProps);
            default:
                throw new Error(`${type} is not a valid schema type`);
        }
        return buildWebSite(otherProps);
    };

    let schema;

    try {
        schema = buildSchema(type, otherProps);
    }
    catch (error) {
        return null;
    }

    const script = [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(schema)
        }
    ];

    return (
        <Helmet script={ script } />
    );
};

JsonLDSchema.propTypes = {
    type: PropTypes.string,
    marketConfig: PropTypes.object

};

export default JsonLDSchema;
