import React from 'react';
import PropTypes from 'prop-types';
import { NewTextField as ReactUITextField } from 'panamera-react-ui';
import withConfig from 'HOCs/withConfig/withConfig';
import { THEMES } from 'Constants/bundles';

export const TextInputField = ({ config, ...restProps }) => {
    const id = config?.get?.('theme')?.id;

    return (
        <ReactUITextField { ...restProps } roundedInputPixel={ id === THEMES.LETGO } />
    );
};

TextInputField.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func
    }).isRequired
};

export default withConfig(TextInputField);
