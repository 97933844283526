import { useEffect, useState } from 'react';

/**
 * Loads Third-Party Scripts Dynamically
 * @param scriptUrl
 * @returns {{ready: boolean, failed: boolean}}
 */
export const useDynamicScript = scriptUrl => {
    const [ready, setReady] = useState(false);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        let element;

        if (scriptUrl) {
            element = document.createElement('script');

            element.src = scriptUrl;
            element.type = 'text/javascript';
            element.async = true;

            setReady(false);
            setFailed(false);

            element.onload = () => {
                setReady(true);
            };

            element.onerror = () => {
                setReady(false);
                setFailed(true);
            };

            document.head.appendChild(element);
        }

        return () => {
            if (scriptUrl) {
                document.head.removeChild(element);
            }
        };
    }, [scriptUrl]);

    return {
        ready,
        failed
    };
};
