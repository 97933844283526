/* global window */

const getBrowserDetails = () => {
    const { userAgent } = window.navigator;

    if (!userAgent) {
        return 'Unknown';
    }
    let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+(\.\d+)*)/i) || [];

    if (match.length === 0) {
        return 'Unknown';
    }

    let temp;

    // Check for IE
    if (/trident/i.test(match[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return `IE ${temp[1] || ''}`;
    }

    // Check for Opera and Edge
    if (match[1] === 'Chrome') {
        // For Opera
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if (temp !== null) {
            return temp.slice(1).join(' ').replace('OPR', 'Opera');
        }

        // For Edge
        temp = userAgent.match(/\b(Edg)\/(\d+)/);
        if (temp !== null) {
            return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)');
        }
    }

    // Getting version of browser
    match = match[2] ? [match[1], match[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    temp = userAgent.match(/version\/((\d+.)*)/i);

    if (temp !== null) {
        match.splice(1, 1, temp[1]);
    }
    return match.join(' ');
};

export {
    getBrowserDetails
};
