import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';

import { useConsumerFinanceLogic as useConsumerFinanceLogicCl } from './hooks/useConsumerFinanceLogicCl';

import { getLocale } from 'Selectors/config';
import { useLeadGeneration } from 'Hooks/useLeadGeneration/useLeadGeneration';
import withConfig from 'HOCs/withConfig/withConfig';
import { getFinanceSetting } from 'Actions/consumerFinance';

export const mapStateToProps = state => ({
    financeConfig: state.financeConfig,
    financeSetting: state.financeSetting,
    locale: getLocale(state)
});

export const mapDispatchToProps = (dispatch, { config }) => ({
    getSettingsData: () => dispatch(getFinanceSetting(config))
});

export const withConsumerFinanceHook = Comp => {
    function WrappedComp({ financeConfig, financeSetting, getSettingsData, config, ...props }) {
        const { isFetching, data } = financeSetting;
        const fetchFinanceConfig = config.get('financeTabConfig', 'fetchFinanceConfig');

        useEffect(() => {
            if (fetchFinanceConfig && !isFetching && !data) {
                getSettingsData();
            }
        }, [fetchFinanceConfig, isFetching, data, getSettingsData]);
        return (
            <Comp
                useConsumerFinanceLogic={ useConsumerFinanceLogicCl }
                useLeadGeneration={ useLeadGeneration }
                financeConfig={ financeConfig }
                financeSetting={ financeSetting.data }
                config={ config }
                { ...props } />
        );
    }

    WrappedComp.propTypes = {
        financeConfig: PropTypes.object,
        financeSetting: PropTypes.object,
        getSettingsData: PropTypes.func,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired
    };

    return compose(
        withConfig,
        injectIntl,
        connect(mapStateToProps, mapDispatchToProps)
    )(WrappedComp);
};

export default withConsumerFinanceHook;
