import React from 'react';
import PropTypes from 'prop-types';

const SkeletonWrapper = ({
    width,
    height,
    style,
    preserveAspectRatio,
    className,
    children,
    primaryColor,
    secondaryColor,
    speed,
    viewPort,
    rectContainerHeight,
    rectContainerWidth,
    svgProps
}) => {
    const idClip = '_sk_cp_';
    const idGradient = '_sk_lg_';
    const rectFill = `url(#${idGradient})`;

    return (
        <svg
            version="1.1"
            style={ style }
            preserveAspectRatio={ preserveAspectRatio }
            className={ className }
            height={ viewPort.height }
            width={ viewPort.width }
            { ...svgProps }
        >
            <rect
                style={ { fill: rectFill } }
                clipPath={ `url(#${idClip})` }
                x="0"
                y="0"
                width={ rectContainerWidth || width }
                height={ rectContainerHeight || height }
            />

            <defs>
                <clipPath id={ idClip }>{children}</clipPath>

                <linearGradient id={ idGradient }>
                    <stop offset="0%" stopColor={ primaryColor }>
                        <animate
                            attributeName="offset"
                            values="-2; 1"
                            dur={ `${speed}s` }
                            repeatCount="indefinite"
                        />
                    </stop>
                    <stop offset="50%" stopColor={ secondaryColor }>
                        <animate
                            attributeName="offset"
                            values="-1.5; 1.5"
                            dur={ `${speed}s` }
                            repeatCount="indefinite"
                        />
                    </stop>
                    <stop offset="100%" stopColor={ primaryColor }>
                        <animate
                            attributeName="offset"
                            values="-1; 2"
                            dur={ `${speed}s` }
                            repeatCount="indefinite"
                        />
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    );
};

SkeletonWrapper.defaultProps = {
    speed: 2,
    width: 1,
    height: 1,
    primaryColor: '#f0f0f0',
    secondaryColor: '#e0e0e0',
    preserveAspectRatio: 'xMidYMid meet',
    viewPort: {
        height: null,
        width: null
    },
    rectContainerHeight: null,
    rectContainerWidth: null,
    svgProps: {}
};

SkeletonWrapper.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.number,
    style: PropTypes.object,
    preserveAspectRatio: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.array,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    speed: PropTypes.number,
    viewPort: PropTypes.object,
    rectContainerHeight: PropTypes.string,
    rectContainerWidth: PropTypes.string,
    svgProps: PropTypes.object
};

export default SkeletonWrapper;
