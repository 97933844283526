export const BASE_URL = 'inspection';
export const BOOKING_URL = 'booking';
export const SELECT_LOCATION_URL = 'select-location';
export const HOME_INSPECTION_URL = 'home';
export const STORE_INSPECTION_URL = 'store';
export const CONFIRMATION_URL = 'confirmation';
export const NON_CMC_CITY_URL = 'noncmc';

export const REVAMP_SCREENS = {
    SELECT_LOCATION: 'SELECT_LOCATION',
    BOOKING: 'BOOKING',
    HOME: 'HOME',
    STORE: 'STORE',
    CONFIRMATION: 'CONFIRMATION',
    NON_CMC: 'NON_CMC'
};

export const REVAMP_URLS = {
    [REVAMP_SCREENS.SELECT_LOCATION]: `/${BASE_URL}/${SELECT_LOCATION_URL}`,
    [REVAMP_SCREENS.BOOKING]: `/${BASE_URL}/${BOOKING_URL}`,
    [REVAMP_SCREENS.HOME]: `/${BASE_URL}/${BOOKING_URL}/${HOME_INSPECTION_URL}`,
    [REVAMP_SCREENS.STORE]: `/${BASE_URL}/${BOOKING_URL}/${STORE_INSPECTION_URL}`,
    [REVAMP_SCREENS.CONFIRMATION]: `/${BASE_URL}/${BOOKING_URL}/${CONFIRMATION_URL}`,
    [REVAMP_SCREENS.NON_CMC]: `/${BASE_URL}/${BOOKING_URL}/${NON_CMC_CITY_URL}`
};
