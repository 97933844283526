import React, { useMemo } from 'react';
import { useDynamicScript } from 'Hooks/useDynamicScript/useDynamicScript';
import PropTypes from 'prop-types';
import { loadComponent } from 'Helpers/loadComponent';

const RemoteComponent = ({ system: { url, scope, module }, Loader, ...rest }) => {
    const { ready, failed } = useDynamicScript(url);
    // Memoize lazily loaded component as we are calling react.lazy inline
    const Component = useMemo(() => {
        if (ready) {
            return React.lazy(
                loadComponent(scope, module)
            );
        }
        return null;
    }, [scope, module, ready]);

    if (!ready) {
        return Loader || <h2>Loading dynamic script: {url}</h2>;
    }

    if (failed) {
        return <h2>Failed to load dynamic script: {url}</h2>;
    }

    return (
        <React.Suspense fallback={ Loader || 'Loading System' }>
            <Component { ...rest } />
        </React.Suspense>
    );
};

RemoteComponent.propTypes = {
    system: PropTypes.shape({
        scope: PropTypes.string,
        url: PropTypes.string,
        module: PropTypes.string
    }),
    Loader: PropTypes.element
};

export default RemoteComponent;
