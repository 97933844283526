import React, { memo as reactMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Overlay } from 'panamera-react-ui';
import withConfig from 'HOCs/withConfig/withConfig';
import UserDetailsFormWidget from './UserDetailsFormWidget/UserDetailsFormWidget';
import css from './UserDetailsForm.APP_TARGET.scss';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

export const UserDetailsFormWithOverlay = ({
    intl,
    config,
    item,
    onClose,
    thankYouTitle,
    thankYouSubtitle,
    dealer,
    userFlow,
    eventType,
    hp_tracking_event,
    eventOrigin,
    onSetThankYou,
    onLinkTap,
    onFieldBlur,
    onSubmitComplete,
    track
}) => (
    <Overlay
        isOpened
        onClose={ onClose }
        overlayClassName={ css.overlay }
    >
        <div className={ css.modal }>
            <NewIconButton
                icon="cross"
                color="primaryBase"
                size={ 24 }
                onClick={ onClose }
                className={ css.userDetailsForm__closeBtn }
                onChange={ onClose }
                onSetThankYou={ onSetThankYou }
            />
            <UserDetailsFormWidget
                css={ css }
                intl={ intl }
                config={ config }
                item={ item }
                dealer={ dealer }
                thankYouTitle={ thankYouTitle }
                thankYouSubtitle={ thankYouSubtitle }
                eventType={ eventType }
                eventOrigin={ eventOrigin }
                userFlow={ userFlow }
                onLinkTap={ onLinkTap }
                onFieldBlur={ onFieldBlur }
                onSetThankYou={ onSetThankYou }
                onSubmitComplete={ onSubmitComplete }
                hp_tracking_event={ hp_tracking_event }
                track={ track }
            />
        </div>
    </Overlay>
);

UserDetailsFormWithOverlay.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func
    }).isRequired,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    onClose: PropTypes.func,
    item: PropTypes.object,
    dealer: PropTypes.object,
    thankYouSubtitle: PropTypes.string,
    thankYouTitle: PropTypes.string,
    eventType: PropTypes.string,
    hp_tracking_event: PropTypes.string,
    eventOrigin: PropTypes.string,
    userFlow: PropTypes.string,
    onSetThankYou: PropTypes.func.isRequired,
    onLinkTap: PropTypes.func,
    onFieldBlur: PropTypes.func,
    onSubmitComplete: PropTypes.func,
    track: PropTypes.func
};

UserDetailsFormWithOverlay.defaultProps = {
    onClose: () => {},
    onSetThankYou: () => {}
};

export default compose(
    withConfig,
    injectIntl,
    reactMemo
)(UserDetailsFormWithOverlay);
