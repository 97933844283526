import React from 'react';
import css from './Tabs.APP_TARGET.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TabsUI = ({
    tabsData,
    onTabClick,
    tabsClassName = '',
    tabName }) => {
    const onTabClickHandler = event => {
        const { currentTarget: { id: index } = {}} = event;

        if (index) {
            onTabClick(tabsData[parseInt(index, 10)]);
        }
    };

    return (
        <div className={ classNames(css.tabs, tabsClassName) }>
            {tabsData.map(({ title, key }, index) => {
                return (
                    <div
                        className={ classNames(css.tabs__title, {
                            [css.tabs__selected]: key === tabName || (!tabName && index === 0)
                        }) }
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={ onTabClickHandler }
                        key={ index }
                        id={ index }
                        data-aut-id={ key }
                    >{title}</div>
                );
            })}
        </div>
    );
};

TabsUI.propTypes = {
    tabsData: PropTypes.array,
    onTabClick: PropTypes.func,
    tabName: PropTypes.string,
    tabsClassName: PropTypes.string
};

export default React.memo(TabsUI);
