/**
 * Load Dynamic Remotes for Webpack Module Federation
 * @param scope - the shareScope Object OR scope key
 * @param module - the remote global name
 * @returns {Promise<object>} - Federated Module Container
 */
export const loadComponent = (scope, module) => {
    return async () => {
        /* eslint-disable no-undef */
        // Initializes the share scope. This fills it with known provided modules from this build and all remotes
        await __webpack_init_sharing__('default');
        const container = window[scope]; // or get the container somewhere else

        // Initialize the container, it may provide shared modules
        await container.init(__webpack_share_scopes__.default);
        /* eslint-enable no-undef */
        return (await container.get(module))();
    };
};
