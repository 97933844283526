import React from 'react';
import { fileNames } from 'Constants/imageUrls';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import withConfig from 'HOCs/withConfig/withConfig';
import PropTypes from 'prop-types';
import cx from 'classnames';

import css from './ThankYouWidget.APP_TARGET.scss';

export const ThankYouWidget = ({
    className,
    imgClassName,
    textClassName,
    withBg,
    config,
    title,
    subTitle,
    titleClassName,
    imgFilePath
}) => {
    const theme = config?.get('theme', 'id');

    return (
        <div className={ cx(
            css.thankYouWrapper,
            { [css.withBg]: withBg },
            className
        ) }>
            <ImageWrapper
                fileName={ imgFilePath || (theme === 'otoplus' ? fileNames.THANK_YOU_TR : fileNames.THANK_YOU) }
                className={ cx(css.thankYouImg, imgClassName) }
            />
            <div className={ cx(css.thankYouTextWrapper, textClassName) }>
                <div className={ cx(css.thankYouTitle, titleClassName) } >{title}</div>
                <div className={ css.thankYouSubtitle } >{subTitle}</div>
            </div>
        </div>
    );
};

ThankYouWidget.propTypes = {
    withBg: PropTypes.bool,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    subTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    className: PropTypes.string,
    imgClassName: PropTypes.string,
    textClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    imgFilePath: PropTypes.string
};

ThankYouWidget.defaultProps = {
    withBg: false,
    className: '',
    imgClassName: '',
    textClassName: '',
    titleClassName: '',
    imgFilePath: ''
};

export default withConfig(ThankYouWidget);
