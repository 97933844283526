// Filter 2.0
import { createSelector } from 'reselect';
import { isObject, pickBy } from 'Helpers/objects';
import { itemsFacetMetadataSelector, userSessionSelector } from './items';
import { getFullFiltersInfo, getVisualizationFilters } from 'Selectors/filtersTanak';
import { removeExcludedFilters } from 'Helpers/filtersTanak';
import { getMergedPopularConfig, getMergedCustomConfig, decodeFilterObj } from 'Helpers/filtersAbundance';
import { arrayToObject } from 'Helpers/array';
import { FILTER_NO_ABUNDANCE } from 'Constants/filters';
import * as stringsHelper from 'Helpers/strings';
import { selectedLocationSelector, gpsSelector, isCurrentGPSLocationSelector } from './location';
import { ITEMS_SEARCH_SOURCE } from 'Constants/items';
import { getLocale } from './config';

export const getFilterSearchParamSelector = createSelector(
    (_, { params, text }) => {
        const textParams = params && params.text;

        return text || stringsHelper.decodeSearch(textParams);
    },
    (_, { params }) => params && params.categoryID,
    (_, { params }) => params && params.geoID,
    (state, props) => selectedLocationSelector(state, props),
    (_, { filters }) => filters,
    (_, { sorting }) => sorting,
    gpsSelector,
    isCurrentGPSLocationSelector,
    (_, { location }) => location && location.query && location.query.page,
    userSessionSelector,
    (_, { facetLimit }) => facetLimit,
    (_, { spellCheckFlag }) => spellCheckFlag,
    (state, { params }) => state && state.track && state.track.origin === ITEMS_SEARCH_SOURCE
            && params.isSearchCall,
    (...args) => {
        const [
            query,
            category,
            locationByUrl,
            locationState,
            filters,
            sorting,
            gps,
            isGPSLocation,
            page,
            user,
            facetLimit,
            spellCheckFlag,
            isSearchCall
        ] = args;
        const { latitude, longitude } = gps || {};
        const locationId = (!locationByUrl && locationState)
            ? locationState.id
            : locationByUrl;
        const location = isGPSLocation ? undefined : locationId;
        const facetLimitObject = facetLimit
            ? { facet_limit: facetLimit, location_facet_limit: 20 }
            : {};

        // spelchecker will only work when user searches something and spellcheck flag is setup
        const spellcheck = spellCheckFlag && !!query;

        return pickBy({
            query,
            category,
            spellcheck,
            location,
            latitude,
            longitude,
            ...decodeFilterObj(filters),
            ...facetLimitObject,
            sorting,
            page,
            user,
            isSearchCall
        });
    }
);

export const getAbundanceFilterInfo = createSelector(
    getFullFiltersInfo,
    itemsFacetMetadataSelector,
    state => state.filtersAbundance && state.filtersAbundance.data,
    getLocale,
    (_, categoryID) => categoryID,
    (_, __, marketConfig) => marketConfig,
    (_, __, ___, forceUseSearchMetadata) => forceUseSearchMetadata,
    (filtersInfo, metadata, filtersMetadata, locale, categoryID, marketConfig, forceUseSearchMetadata) => {
        let filtersAbundance = (filtersMetadata && filtersMetadata.filters) ? filtersMetadata.filters : (metadata && metadata.filters);

        if (forceUseSearchMetadata) {
            filtersAbundance = metadata && metadata.filters;
        }

        const abundanceFilters = (filtersAbundance && removeExcludedFilters(filtersAbundance)) || [];
        const abundanceFilterInfo = arrayToObject(abundanceFilters, 'id');
        const hideZeroAbundanceOptions = marketConfig?.get('olxAutos', 'isZeroAbundanceOption');
        const carsCategory = marketConfig?.get('adpvAuto', 'carCategory');
        const isCarCategory = categoryID === carsCategory;
        const isFilterFacetOptimizationEnabled = marketConfig?.get('filtersFacetOptimizationEnable') || false;
        const filterFacetOptimizationList = isFilterFacetOptimizationEnabled ? marketConfig?.get('filtersFacetOptimizationList') : [];

        return Object.keys(filtersInfo).reduce((mergedFiltersInfo, attribute) => {
            const filter = filtersInfo[attribute];
            const abundanceFilter = abundanceFilterInfo[attribute];

            // Safety Check
            if (!(isObject(filter) && isObject(abundanceFilter))) {
                if (!hideZeroAbundanceOptions) {
                    return {
                        ...mergedFiltersInfo,
                        [attribute]: filter
                    };
                }

                return {
                    ...mergedFiltersInfo
                };
            }

            // handling for model
            if (FILTER_NO_ABUNDANCE[attribute]) {
                if (!hideZeroAbundanceOptions) {
                    return {
                        ...mergedFiltersInfo,
                        [attribute]: filter
                    };
                }

                const modelFilterInfo = { ...filter };

                modelFilterInfo.render.customConfiguration.values = abundanceFilter.values?.map(option => option.id).sort();

                return {
                    ...mergedFiltersInfo,
                    [attribute]: modelFilterInfo
                };
            }

            const {
                render_as: componentType,
                popular_values_configuration: abundancePopularConfig
            } = abundanceFilter;
            const { render: renderFilterInfo, ...restFilterInfo } = filter;
            const {
                popularConfiguration: popularConfig,
                customConfiguration: customConfig,
                ...restRenderFilterInfo
            } = renderFilterInfo;

            const mergedRenderFilterInfo = { ...restRenderFilterInfo };
            const shouldOptimizedFilterFacet = isFilterFacetOptimizationEnabled && filterFacetOptimizationList?.includes(filter.attribute);

            if (isObject(customConfig)) {
                mergedRenderFilterInfo.customConfiguration
                    = getMergedCustomConfig(abundanceFilterInfo, attribute, customConfig, componentType, locale, hideZeroAbundanceOptions, isCarCategory, shouldOptimizedFilterFacet);
            }
            else {
                mergedRenderFilterInfo.customConfiguration = customConfig;
            }

            if (isObject(popularConfig) && isObject(abundancePopularConfig)) {
                mergedRenderFilterInfo.popularConfiguration
                    = getMergedPopularConfig(abundancePopularConfig, popularConfig, componentType, locale, hideZeroAbundanceOptions, isCarCategory, shouldOptimizedFilterFacet);
            }
            else {
                mergedRenderFilterInfo.popularConfiguration = popularConfig;
            }

            // to remove range histogram filters in case of null results
            if (hideZeroAbundanceOptions && !mergedRenderFilterInfo.popularConfiguration && !mergedRenderFilterInfo.customConfiguration?.values) {
                return {
                    ...mergedFiltersInfo
                };
            }

            return {
                ...mergedFiltersInfo,
                [attribute]: { render: { ...mergedRenderFilterInfo }, ...restFilterInfo }
            };
        }, {});
    }
);

export const getCombinedAbundanceFilterInfo = createSelector(
    getAbundanceFilterInfo,
    getVisualizationFilters,
    (filters, visualizationFilters) => ({ ...filters, ...visualizationFilters })
);
