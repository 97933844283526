/* global window */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Icon } from 'panamera-react-ui';
import css from './StickyWhatsappButton.scss';
import { connect } from 'react-redux';
import withTrack from 'HOCs/withTrack/withTrack';
import { isMobile } from 'Helpers/devices';
import { FormattedMessage as Translation } from 'react-intl';

export const STICKY_WHATSAPP_BUTTON_EXPERIMENT = 'PAN-68737';

const StickyWhatsappButton = ({ disclaimer, tabName = '', track, variant, whatsAppLink, onWhatsAppClick, className, iconClassName, iconSize }) => {
    const { whatsAppNumber } = disclaimer?.config ?? {};

    function makeWhatsAppLink() {
        const number = whatsAppNumber?.replace(/\D/g, '');

        return `https://api.whatsapp.com/send?phone=${number}`;
    }

    function handleWhatsAppClick() {
        track('contact_button_tap', {
            chosen_option: 'whatsapp_icon',
            select_from: tabName
        });
        window.open(makeWhatsAppLink(), '_blank');
    }

    return (whatsAppLink || whatsAppNumber) ? (
        <a title="whatsapp"
            id="whatsapp_button"
            href={ whatsAppLink || makeWhatsAppLink() }
            className={ `${css.whatsApp} ${variant === 'c' ? css.whatsappWithText : ''} ${className || (isMobile ? '' : css.whatsappDesktop)}` }
            target="_blank"
            rel="noreferrer"
            onClick={ whatsAppLink ? onWhatsAppClick : handleWhatsAppClick }
        >
            {variant === 'c' && <div className={ css.whatsappButtonText }>
                <Translation
                    id="sticky_whatsapp_button_text"

                />
            </div>}
            <div className={ iconClassName || css.whatsappIcon }>
                <Icon

                    icon={ 'whatsapp' }
                    color="white"
                    // eslint-disable-next-line no-magic-numbers
                    size={ iconSize || 32 }
                />
            </div>
        </a>
    ) : null;
};

const mapStateToProps = state => {
    return {
        disclaimer: state.bookAppointment.disclaimer.data,
        tabName: state.cxeLayout.tabName
    };
};

StickyWhatsappButton.propTypes = {
    track: PropTypes.func.isRequired,
    disclaimer: PropTypes.object,
    tabName: PropTypes.string,
    variant: PropTypes.string,
    whatsAppLink: PropTypes.string,
    onWhatsAppClick: PropTypes.func,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    iconSize: PropTypes.number
};

export default compose(
    withTrack,
    connect(mapStateToProps))(StickyWhatsappButton);
