import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import PropTypes from 'prop-types';
export const SliderBanner = ({
    settings,
    images,
    getFileRestrictions,
    isDesktop,
    renderArrow
}) => {
    const slider = useRef(null);
    const [currentIdx, updateCurrentIdx] = useState(0);

    function handleDotClick(idx) {
        slider.current.slickGoTo(idx);
        updateCurrentIdx(idx);
    }

    function onAfterChange(newIdx) {
        updateCurrentIdx(newIdx);
    }

    function replaceTokensInUrls(url) {
        return url?.replace(/\$ext\$/g, 'png').replace(/\$width\$/g, getFileRestrictions.width);
    }

    function next(ev) {
        ev.stopPropagation();
        slider.current.slickNext();
    }

    function previous(ev) {
        ev.stopPropagation();
        slider.current.slickPrev();
    }

    return (
        <>
            {renderArrow(previous, next)}
            <Slider { ...settings } afterChange={ onAfterChange } ref={ slider }>
                {images?.map((image, index) => (
                    <div key={ index }>
                        <img
                            src={ replaceTokensInUrls(images.length > 0 ? image.image.url : []) }
                            alt={ `Slide ${index + 1}` }
                            style={ { ...image.image.style, cursor: 'pointer' } }
                            onClick={ () => {
                                if (typeof window !== 'undefined' && image.target === '_blank') {
                                    // eslint-disable-next-line no-undef
                                    window.open(image.action, '_blank')?.focus();
                                }
                            } }
                        />
                    </div>
                ))}
            </Slider>
            <div style={ { width: '100vw' } } >
                <div style={ {
                    width: '100%',
                    display: images.length > 1 ? 'flex' : 'none',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: isDesktop ? '280px' : '15px'
                } }>
                    {images.map((_, idx) => (
                        <div key={ idx }
                            data-aut-id={ `carousel-slide-${idx}` }
                            style={ {
                                cursor: 'pointer',
                                width: 10,
                                height: 10,
                                backgroundColor: idx === currentIdx ? 'rgba(10, 10, 10, 0.8)' : 'gray',
                                borderRadius: 50,
                                margin: '0 5px'
                            } }
                            onClick={ () => handleDotClick(idx) }
                        />))}
                </div>
            </div>
        </>
    );
};

SliderBanner.propTypes = {
    settings: PropTypes.any,
    getFileRestrictions: PropTypes.any,
    images: PropTypes.any,
    isDesktop: PropTypes.any,
    renderArrow: PropTypes.any
};

export default SliderBanner;

