import { isEmpty } from 'Helpers/objects';
import { getUrlWithParams } from './actions';
import { getLocParams } from './helpers';
import { getType } from 'Reducers/helpers/typeModifier';
import { TYPE } from './reducer';
import { createSelector } from 'reselect';
import { CampaingHelpers, REQUEST_ORIGINS, REQUEST_ORIGIN } from 'olx-autos-landing-page';
import { APPOINTMENT_FLOW_TYPES, APPOINTMENT_FLOW_TYPES_RAW } from './constants';
import { getItem as getSessionStorageItem } from 'Helpers/sessionStorage';

export const isFetching = (state, selectedLocation) => {
    const params = getLocParams(selectedLocation);
    const key = getUrlWithParams(state, params);

    const type = getType(TYPE, key);

    return state.bookAppointment.centres.isFetching[type];
};

export const isError = (state, selectedLocation) => {
    const params = getLocParams(selectedLocation);
    const key = getUrlWithParams(state, params);

    const type = getType(TYPE, key);

    return state.bookAppointment.centres.isError[type];
};

export const getCityWiseCentres = (state, selectedLocation) => {
    const params = getLocParams(selectedLocation);
    const key = getUrlWithParams(state, params);
    const type = getType(TYPE, key);

    return isEmpty(state.bookAppointment.centres.collections[type]) ? {}
        : state.bookAppointment.centres.collections[type].cityWiseCentres
        && state.bookAppointment.centres.collections[type].cityWiseCentres[0];
};

export const getCityWiseCentresV3 = (state, isPPCFlow) => {
    const coordinates = state.bookAppointment.locationMapDetails.coordinates || {};
    const citycode = state?.bookAppointment?.locationMapDetails?.citycode;

    const key = getUrlWithParams(state, { ...coordinates, isPPCFlow, citycode }, 'V2');
    const type = getType(TYPE, key);

    return isEmpty(state.bookAppointment.centres.collections[type]) ? {}
        : state.bookAppointment.centres.collections[type][0];
};

export const isFetchingV2 = state => {
    const key = getUrlWithParams(state, state.bookAppointment.locationMapDetails.coordinates || {}, 'V2');

    const type = getType(TYPE, key);

    return state.bookAppointment.centres.isFetching[type];
};

export const isErrorV2 = state => {
    const key = getUrlWithParams(state, state.bookAppointment.locationMapDetails.coordinates || {}, 'V2');

    const type = getType(TYPE, key);

    return state.bookAppointment.centres.isError[type];
};

export const getAllCentres = (state, isPPCFlow) => {
    const coordinates = state.bookAppointment?.locationMapDetails?.coordinates || {};
    const citycode = state.bookAppointment?.locationMapDetails?.citycode;
    const key = getUrlWithParams(state, { ...coordinates, isPPCFlow, citycode }, 'V2');
    const type = getType(TYPE, key);

    return isEmpty(state.bookAppointment?.centres?.collections[type]) ? []
        : state.bookAppointment.centres.collections[type];
};

export const getSelectedCity = createSelector(
    state => state.bookAppointment.cmcCities.data.allCities,
    (_, { params }) => params.cityName,
    (allCities, cityName) => {
        if (!cityName) {
            return null;
        }
        const selectedCity = allCities.find(({ name }) => name.toLowerCase() === cityName.toLowerCase());

        return selectedCity || null;
    }
);

export const getCityWiseCentresV2 = (state, props) => {
    const selectedCity = getSelectedCity(state, props);

    if (!selectedCity) {
        return null;
    }

    const cityWiseCentres = getCityWiseCentres(state, { id: selectedCity.id });

    if (!cityWiseCentres || !cityWiseCentres.centres) {
        return null;
    }

    return cityWiseCentres.centres;
};

export const getSelectedCentre = (state, props) => {
    const centreList = getCityWiseCentresV2(state, props);

    if (!centreList) {
        return null;
    }
    const { params: { centreName }} = props;

    if (!centreName) {
        return null;
    }
    const selectedCentre = centreList.find(centre => centre.name.toLowerCase() === centreName.toLowerCase());

    return selectedCentre || null;
};

export const getDefaultCentre = (state, selectedLocation) => {
    const params = getLocParams(selectedLocation);
    const key = getUrlWithParams(state, params);
    const type = getType(TYPE, key);

    return isEmpty(state.bookAppointment.centres.collections[type]) ? {}
        : state.bookAppointment.centres.collections[type].defaultCentre;
};

export const getIsCancelled = state => {
    return state.bookAppointment.postAppointment.data
        && state.bookAppointment.postAppointment.data.status === 'CANCELLED';
};

export const getDefaultOriginValue = origin => {
    let retVal = '';

    switch (origin) {
        case APPOINTMENT_FLOW_TYPES_RAW.POSTING:
            retVal = APPOINTMENT_FLOW_TYPES.O2O_POSTING;
            break;
        case APPOINTMENT_FLOW_TYPES_RAW.LISTING:
            retVal = APPOINTMENT_FLOW_TYPES.O2O_LISTING;
            break;
        default:
            retVal = APPOINTMENT_FLOW_TYPES.ORGANIC_DIRECT;
    }
    return retVal;
};

export const getFlowType = createSelector(
    (__, _, config) => config,
    (_, location) => location && location.query,
    state => state.track.origin,
    (config, query, origin) => {
        if (query && CampaingHelpers.isPaidCampaign(query, config)) {
            return APPOINTMENT_FLOW_TYPES.PAID_DIRECT;
        }

        const requestOrigin = getSessionStorageItem(REQUEST_ORIGIN);

        if (requestOrigin === REQUEST_ORIGINS.HOME_PAGE) {
            return APPOINTMENT_FLOW_TYPES.O2O_HOMEPAGE_WIDGET;
        }

        if (requestOrigin === REQUEST_ORIGINS.LISTING_PAGE) {
            return APPOINTMENT_FLOW_TYPES.O2O_LISTING;
        }
        if (requestOrigin === REQUEST_ORIGINS.SELL_FLOW) {
            return APPOINTMENT_FLOW_TYPES.O2O_POSTING;
        }

        if (requestOrigin === REQUEST_ORIGINS.EVALUATION_FORM) {
            return origin === 'home' ? APPOINTMENT_FLOW_TYPES.O2O_HOMEPAGE_WIDGET : getDefaultOriginValue(origin);
        }

        return APPOINTMENT_FLOW_TYPES.ORGANIC_DIRECT;
    }
);
