import { isRejected, isPending, isLimited, isDisabled } from './adActionStatus';

const MAX_NUMBER = 1000;
const LARGE_NUMBER_DISPLAY_FORMAT = '999+';

export const ICON_LIKE_SIZE = 16;

export const ICON_MID_SIZE = 18;
export const ICON_VIEW_SIZE = 20;

export const formatLargeNumber = value => {
    if (typeof value === 'number' && value > MAX_NUMBER) {
        return LARGE_NUMBER_DISPLAY_FORMAT;
    }
    return value;
};

export const getStatisticValue = (status, value) => {
    if (isRejected(status) || isPending(status) || isLimited(status) || isDisabled(status) || value === null) {
        return '-';
    }

    return formatLargeNumber(value) || 0;
};
