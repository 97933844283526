import React from 'react';
import SkeletonWrapper from './SkeletonWrapper';
import { isMobile } from 'Helpers/devices';

const CFWidgetPlaceHolder = () => {
    const width = isMobile ? '100%' : '400';
    const height = 430;
    const style = { backgroundColor: '#fff', borderRadius: '4px', marginTop: isMobile ? '-10px' : '0' };
    const viewPort = { height, width };

    return (
        <SkeletonWrapper
            width={ width }
            height={ height }
            style={ style }
            viewPort={ viewPort }
            rectContainerHeight="100%"
            rectContainerWidth="100%"
            preserveAspectRatio="xMinYMin meet"
        >
            <rect x="100" y="18" rx="3" ry="3" width="50%" height="16" />
            <rect x="135" y="42" rx="3" ry="3" width="30%" height="14" />
            <rect x="0" y="70" width="100%" height="2" />
            <rect x="10" y="90" rx="3" ry="3" width="95%" height="200" />
            <rect x="0" y="310" width="100%" height="12" />
            <rect x="40" y="350" rx="3" ry="3" width="80%" height="40" />
        </SkeletonWrapper>
    );
};

export default CFWidgetPlaceHolder;
