import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'panamera-react-ui';

import { scrollToTop } from 'Helpers/page';
import { MAX_POPULAR_SEARCHES } from 'Constants/popularSearches';

const MobileLinksSearch = ({ searches }) => {
    return searches
        .slice(0, MAX_POPULAR_SEARCHES)
        .map(({ term, href }) => (
            <li key={ term }>
                <Link
                    to={ href }
                    onClick={ scrollToTop }
                >
                    { term }
                </Link>
            </li>
        ));
};

MobileLinksSearch.propTypes = {
    searches: PropTypes.array.isRequired
};

export default MobileLinksSearch;
