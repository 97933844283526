import { post } from 'Server';
import {
    URLS
} from 'Reducers/userPhoneValidate';

const validateUserPhone = (phone, countryCode) => dispatch => {
    if (!countryCode || !phone) {
        return Promise.reject();
    }

    const params = {
        phone,
        countryCode
    };

    return dispatch(post(URLS.userPhoneValidate, 'USERS', params));
};

export { validateUserPhone };
