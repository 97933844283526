import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import ValidatedNewTextField from '../Validated/ValidatedNewTextField';
import { configSelector } from 'Selectors/config';
import { PHONE_REGEX_DEFAULT } from 'Constants/users';
import { noop } from 'Helpers/function';

const extras = {
    autoComplete: 'mobile'
};

export function getPhoneRegExp(config) {
    let regex = PHONE_REGEX_DEFAULT;

    if (config?.rules?.phone) {
        const default_regex = config.rules.phone?.default_regex?.value || regex;

        regex = `${default_regex}{${config.rules.phone.min_length.value - 1},${config.rules.phone.max_length.value - 1}}$`;
    }

    return new RegExp(regex);
}

export const Phone = ({ config, ...props }) => {
    const regExpString = getPhoneRegExp(config);

    return (
        <ValidatedNewTextField
            id={ props.name }
            extras={ props.autoCompleteOverRide || extras }
            prefix={ config.location.country.calling_code }
            direction={ 'ltr' }
            regex={ regExpString }
            placeholder={ props.placeholder }
            { ...props }
        />
    );
};

Phone.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    error: PropTypes.node,
    enableSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    config: PropTypes.object.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    dataAutId: PropTypes.string,
    autoCompleteOverRide: PropTypes.object
};

Phone.defaultProps = {
    value: '',
    name: 'phone',
    onChange: noop,
    requiredErrorMessage: <Translation id="mandatoryField" />,
    regexErrorMessage: <Translation id="fieldPhone" />,
    required: true,
    error: '',
    className: '',
    autoFocus: false,
    placeholder: <Translation id="login_sms_enter_phone_number" />,
    dataAutId: '',
    autoCompleteOverRide: null
};

export const mapStateToProps = state => ({
    config: configSelector(state)
});

export default compose(
    injectIntl,
    connect(mapStateToProps)
)(Phone);
