import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import ValidatedNewTextField from '../Validated/ValidatedNewTextField';
import { EMAIL_REGEX } from 'Constants/users';

const extras = {
    autoComplete: 'email'
};

export const Email = props => (
    <ValidatedNewTextField
        autoFocus={ props.autoFocus }
        extras={ extras }
        placeholder={ props.placeholder || props.intl.formatMessage({ id: 'login_email_enter_email_email' }) }
        { ...props }
    />
);

Email.defaultProps = {
    value: '',
    category: 'primary',
    autoFocus: true,
    name: 'email',
    regexErrorMessage: <Translation id="invalidEmail" />,
    required: true,
    regex: EMAIL_REGEX,
    validateOnBlur: false,
    validateOnChange: false,
    dataAutId: ''
};

Email.propTypes = {
    autoFocus: PropTypes.bool,
    category: PropTypes.oneOf(['primary', 'secondary']),
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    placeholder: PropTypes.string,
    dataAutId: PropTypes.string
};

export default injectIntl(Email);
