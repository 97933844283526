import { getStore } from 'Helpers/store';
import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { CampaingHelpers } from 'olx-autos-landing-page';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getUserDataSelector } from 'Selectors/user';
import withTrack from 'HOCs/withTrack/withTrack';
import { default as Placeholder } from './CFWidgetPlaceHolder/CFWidgetPlaceHolder';
import withErrorBoundary from 'HOCs/withErrorBoundary/withErrorBoundary';
import withConfig from 'HOCs/withConfig/withConfig';
import { getFinanceStaticUrl } from 'Helpers/consumerFinance';
import RemoteComponent from 'Components/RemoteComponent/RemoteComponent';
import LeadOtpWrapper from 'Components/LeadOtpWrapper/LeadOtpWrapper';

const CFWidgetAsync = props => {
    const { app, config, placeHolder, widgetInView } = props;
    const [system] = useState({
        url: getFinanceStaticUrl({ app, config }),
        scope: 'remoteCfFinance',
        module: './CfWidget'
    });

    const LazyLoader = placeHolder || Placeholder;

    const Loader = <LazyLoader isPlaceHolder={ true } />;
    const inView = widgetInView || widgetInView === undefined;

    if (typeof window === 'undefined' || !inView) {
        return Loader;
    }
    const store = getStore();

    return (<Suspense fallback={ Loader }>
        <RemoteComponent
            renderFallback={ Loader }
            system={ system }
            isPaidCampaign={ CampaingHelpers.isPaidCampaign }
            store={ store }
            Loader={ Loader }
            LeadOtpWrapper={ LeadOtpWrapper }
            { ...props }
        />
    </Suspense>);
};

CFWidgetAsync.propTypes = {
    widgetInView: PropTypes.bool,
    placeHolder: PropTypes.func,
    wrapperComponent: PropTypes.func,
    app: PropTypes.object.isRequired,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

const mapStateToProps = state => ({
    userData: getUserDataSelector(state)
});

export default compose(
    withTrack,
    withConfig,
    withErrorBoundary(),
    connect(mapStateToProps))(CFWidgetAsync);
