import { useMemo, useState } from 'react';
import { useLeadGeneration } from 'Hooks/useLeadGeneration/useLeadGeneration';
import { LEAD_GEN_SOURCES } from 'Constants/items';
import { EXPONEA_EVENT_TYPES, EXPONEA_USER_FLOW } from 'Constants/exponeaLeads';
import { getEMIChoices } from 'Helpers/financeConfig';
import { getFormattedAmount } from 'Helpers/numbers';
import { PERCENT } from 'Constants/numbers';

export const useConsumerFinanceLogic = ({
    financeConfig,
    config,
    carValue,
    currencySymbol,
    locale,
    dealer,
    onTrack,
    eventName,
    userFlow,
    optionalParams,
    prevHitchValue,
    financeLimits,
    installments_Cl = [],
    handoverToClFlow
}) => {
    const {
        interestRate,
        installments
    } = financeConfig;
    const {
        maxHitchPercentage,
        minHitchPercentage,
        minLoanAmount
    } = financeLimits;
    const tncLink = (config.get('staticAssets') + config.get('redirectionTermsAndConditions')).toString();

    const minHitchAmount = (carValue * minHitchPercentage) / PERCENT;
    const maxHitchAmount = Math.min((carValue * maxHitchPercentage) / PERCENT, carValue - minLoanAmount);

    let hitchAmount = prevHitchValue.current ? Math.max(minHitchAmount, prevHitchValue.current) : minHitchAmount;

    if (hitchAmount > maxHitchAmount) {
        hitchAmount = maxHitchAmount;
    }
    const loanAmount = carValue - hitchAmount;
    const formattedLoanAmount = getFormattedAmount(loanAmount, currencySymbol, locale);

    const repaymentPeriodCounts = useMemo(() => getEMIChoices(loanAmount, interestRate, installments), [installments, interestRate, loanAmount]);
    const [selectedEMIChoice, selectEMIChoice] = useState(repaymentPeriodCounts.length - 1);

    const emi = useMemo(() => {
        let emiDetails = {};

        if (handoverToClFlow) {
            const { plan, amount } = installments_Cl[selectedEMIChoice] || {};

            emiDetails = {
                amount: amount || '',
                repaymentPeriod: plan || ''
            };
        }
        else {
            const { emi, repaymentPeriodCount } = repaymentPeriodCounts[selectedEMIChoice];

            emiDetails = {
                amount: emi || '',
                repaymentPeriod: repaymentPeriodCount || ''
            };
        }

        return emiDetails;
    }, [repaymentPeriodCounts, selectedEMIChoice, installments_Cl, handoverToClFlow]);

    const [
        [name, setName],
        [email, setEmail],
        [phone, setPhone],
        areAllUserValuesValid,
        isPosting,
        isCompleted,
        onLeadSubmit,
        isErr,
        [lastName, setLastName],
        [motherMaidenName, setMotherMaidenName],
        [birthDate, setBirthDate]
    ] = useLeadGeneration({
        eventName: eventName || EXPONEA_EVENT_TYPES.CF_SUBMIT,
        dealer,
        hitchAmount: hitchAmount.toString(),
        installmentPeriod: emi.repaymentPeriod.toString(),
        ow: userFlow || EXPONEA_USER_FLOW.B2C,
        sitecode: config.get('siteCode'),
        onTrack,
        source: LEAD_GEN_SOURCES.FINANCE,
        optionalParams
    });

    const showThankYou = isCompleted;

    return {
        tncLink,
        minHitchAmount,
        maxHitchAmount,
        hitchAmount,
        formattedLoanAmount,
        repaymentPeriodCounts,
        loanAmount,
        emi,
        selectedEMIChoice,
        selectEMIChoice,
        name,
        setName,
        email,
        setEmail,
        phone,
        setPhone,
        areAllUserValuesValid,
        isPosting,
        isErr,
        isCompleted,
        showThankYou,
        onLeadSubmit,
        lastName,
        setLastName,
        motherMaidenName,
        setMotherMaidenName,
        birthDate,
        setBirthDate
    };
};
