export const B2C_DESKTOP_LAYOUT_NAME = 'landing-layout-cmc-olxid-web-desktop';
export const B2C_MOBILE_LAYOUT_NAME = 'landing-layout-cmc-olxid-web-mobile';
export const B2C_DESKTOP_NON_CMC_LAYOUT_NAME = 'landing-layout-olxid-web-desktop';
export const B2C_MOBILE_NON_CMC_LAYOUT_NAME = 'landing-layout-olxid-web-mobile';
export const C2B_DESKTOP_LAYOUT_NAME = 'layout_olxid_web_desktop_org_initial';
export const C2B_MOBILE_LAYOUT_NAME = 'layout_olxid_web_mobile_org_initial';
export const INSPECTION_DESKTOP_LAYOUT_NAME = 'layout_olxid_web_desktop_org_app_cre';
export const INSPECTION_MOBILE_LAYOUT_NAME = 'layout_olxid_web_mobile_org_app_cre';
export const INSPECTION_CREATED_DESKTOP_LAYOUT_NAME = 'olxid-olx-web_desktop-appointment_created';
export const INSPECTION_CREATED_DESKTOP_LAYOUT_NAME_CRE = 'layout_olxid_web_desktop_org_app_cre';
export const VALUATION_DONE_DESKTOP_LAYOUT_NAME = 'olxid-olx-web_desktop-valuation_done';
