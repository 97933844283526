import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'HOCs/withRouter';
import classNames from 'classnames';
import withDirection from 'HOCs/withDirection/withDirection';
import { compose } from 'redux';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import css from './ExpansionPanel.scss';
import { ICON_MID_SIZE } from 'Helpers/statistic';
import { CARET_ICON } from 'Constants/footer';

export class ExpansionPanel extends PureComponent {
    static computeExpanded = (props, state) => {
        if (props.disabled) {
            return false;
        }

        if (typeof props.expanded === 'undefined') {
            return state.expanded || false;
        }

        return props.expanded;
    }

    static propTypes = {
        expanded: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        title: PropTypes.node,
        children: PropTypes.node, // React component class
        id: PropTypes.string,
        containerClassName: PropTypes.string,
        fatherClassName: PropTypes.string,
        childrenClassName: PropTypes.string,
        titleClassName: PropTypes.string,
        iconClassName: PropTypes.string,
        icon: PropTypes.oneOf([CARET_ICON.DOWN, CARET_ICON.RIGHT]),
        direction: PropTypes.oneOf(['ltr', 'rtl']),
        location: PropTypes.object,
        iconSize: PropTypes.number,
        expandedClassName: PropTypes.string
    };

    static defaultProps = {
        onChange: () => {},
        disabled: false,
        id: '',
        containerClassName: '',
        fatherClassName: '',
        childrenClassName: '',
        titleClassName: '',
        iconClassName: '',
        iconSize: ICON_MID_SIZE,
        icon: CARET_ICON.DOWN,
        location: {
            pathname: ''
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            // expanded: ExpansionPanel.computeExpanded(this.props, {})
            expanded: props.expanded
        };
    }
    componentDidMount() {
        if (this.props.expanded) {
            this.props.onChange(null, this.props.expanded, this.props.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ expanded: false });
        }
    }

    onChange = e => {
        if (!this.props.disabled) {
            this.setState(prevState => ({ ...prevState, expanded: !prevState.expanded }), () => {
                this.props.onChange(e, this.state.expanded, this.props.id);
            });
        }
    };

    getDirection = () => {
        const { icon, direction } = this.props;

        return icon === 'arrowRight' && direction === 'rtl'
            ? 'rtl'
            : 'ltr';
    }

    render() {
        const { title, disabled, id, children, containerClassName, icon, fatherClassName, childrenClassName, titleClassName, iconClassName, iconSize, expandedClassName } = this.props;
        // const computedExpanded = ExpansionPanel.computeExpanded(
        //     this.props,
        //     this.state
        // );
        const computedExpanded = this.state.expanded;

        const props = id && { id } || {};

        return (
            <div
                className={ classNames(css.panelContainer, { [css.disabled]: disabled, 'expansion-panel-expanded': computedExpanded }, containerClassName) }
                { ...props }
            >
                <div className={ classNames(css.panelTitleContainer, fatherClassName, { [expandedClassName]: computedExpanded }) } onClick={ this.onChange }>
                    <div className={ classNames(css.panelTitle, 'expansion-panel-title', titleClassName) }>
                        {title}
                    </div>
                    <span className={ classNames(css.icon, iconClassName) }>
                        <Icon icon={ computedExpanded ? 'arrowUp' : icon } direction={ this.getDirection() } size={ iconSize } />
                    </span>
                </div>
                <div
                    className={ classNames({
                        [css.hide]: !computedExpanded
                    }, 'expansion-panel-children', childrenClassName) }
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default compose(
    withDirection,
    withRouter
)(ExpansionPanel);
