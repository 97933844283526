import { TYPE, filterAbundanceUrl } from 'Reducers/filtersAbundance';
import { PLATFORM as APP_TARGET } from 'Constants/device.APP_TARGET';
import { getSessionLong } from 'Helpers/userSession';
import * as urlHelper from 'Helpers/url';
import { sortRelevanceParams } from './items';
import { getFilterSearchParamSelector } from 'Selectors/filtersAbundance';
import { getURLParams } from 'Selectors/url';
import { itemsSearchQuerySelector } from 'Selectors/items';

export const PLATFORM = `web-${APP_TARGET}`;

export const getFilterAbundanceMetadata = ({
    filters,
    sorting,
    facetLimit,
    cookies,
    location,
    spellCheckFlag = true,
    categoryId,
    locationId
}) => (dispatch, getState, { get }) => {
    const state = getState();
    const user = cookies && cookies.onap
        ? { user: getSessionLong(cookies.onap) }
        : {};
    const { location: locationUrl, category, search } = urlHelper.buildObjectFromURL(location.pathname);
    const params = {
        geoID: locationId || locationUrl && locationUrl.id,
        categoryID: categoryId || category && category.id,
        text: search,
        isSearchCall: location.query && location.query.isSearchCall
    };

    const itemsParams = {
        ...user,
        platform: PLATFORM,
        ...getFilterSearchParamSelector(state, { filters, sorting, location, params, facetLimit, spellCheckFlag })
    };

    const sortedRelevanceParams = sortRelevanceParams(itemsParams);

    if (sortedRelevanceParams.query) {
        const qVal = itemsSearchQuerySelector(state, sortedRelevanceParams.text);

        sortedRelevanceParams.query = qVal;
    }

    const stringParams = getURLParams(state, sortedRelevanceParams);
    const url = stringParams ? `${filterAbundanceUrl}?${stringParams}` : filterAbundanceUrl;

    const { filtersAbundance } = state;

    if (filtersAbundance.url === url) {
        return Promise.resolve();
    }
    return dispatch(get(url, TYPE));
};

export const clearFilterAbundanceMetadata = () => ({
    type: `CLEAR_${ TYPE}`
});
