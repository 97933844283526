/* global navigator*/
import uaParserJs from 'ua-parser-js';
import { getError } from 'Helpers/errors';
import { SETTINGS_STATIC_CONSENTS_LIST } from 'Constants/users';

export const getUserLoginDetails = payload => {
    if (navigator && navigator.userAgent) {
        const parser = uaParserJs(navigator.userAgent);

        payload.device_model = parser && parser.device && parser.device.model;
        payload.browser_detail = parser && parser.browser && parser.browser.name;
        payload.os_version = parser && parser.os ? `${parser.os.name} ${parser.os.version}` : '';
    }
    return payload;
};

export const handleAuthError = (res, intl) => {
    let error;
    const errorMsgKey = res.data?.error?.key || null;

    if (errorMsgKey) {
        error = intl.formatMessage({ id: `link_account_${errorMsgKey.toLowerCase()}` });
    }
    else {
        error = getError(res);
    }
    return error;
};

export const mapError = (res, errorsKeys) => {
    let error = errorsKeys.unknown_error;

    if (res?.data?.errorType && errorsKeys[res.data.errorType]) {
        error = errorsKeys[res.data.errorType];
    }
    if (res?.data?.errors?.code && errorsKeys[res.data.errors?.code]) {
        error = errorsKeys[res.data.errors.code];
    }
    return error;
};

export const getConsents = (configConsentsData = [], isMarketingConsents = false) => {
    const consentValues = {};

    configConsentsData.forEach(consentObj => {
        consentObj.channelKeys.forEach(key => {
            consentValues[key.toLowerCase()] = consentObj.isMandatory ? true : isMarketingConsents;
        });
    });

    SETTINGS_STATIC_CONSENTS_LIST.forEach(consent => {
        if (consentValues[consent.key.toLowerCase()] === undefined) {
            consentValues[consent.key.toLowerCase()] = true;
        }
    });

    return consentValues;
};
