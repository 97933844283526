import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { noop } from 'Helpers/function';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { validateUserPhone } from 'Actions/userPhoneValidate';

const prefixCountryCodeMap = {
    '+56': 'CL',
    '+52': 'MX',
    '+91': 'IN',
    '+62': 'ID',
    '+54': 'AR',
    '+57': 'CO',
    '+90': 'TR'
};
const validateApiTimer = 1000;

const validateElement = Element => {
    class ValidatedInput extends React.Component {
        static propTypes = {
            name: PropTypes.string.isRequired,
            value: PropTypes.string,
            onChange: PropTypes.func,
            onBlur: PropTypes.func,
            required: PropTypes.bool,
            formatValue: PropTypes.func,
            requiredErrorMessage: PropTypes.node,
            regex: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(RegExp)
            ]),
            regexErrorMessage: PropTypes.node,
            validate: PropTypes.func,
            validateErrorMessage: PropTypes.node,
            validateOnBlur: PropTypes.bool,
            validateOnChange: PropTypes.bool,
            validateOnMount: PropTypes.bool,
            error: PropTypes.node,
            enableSubmit: PropTypes.func,
            validateUserEnteredNumber: PropTypes.func,
            prefix: PropTypes.string,
            shouldAPIValidatePhone: PropTypes.bool,
            isL2QLogin: PropTypes.bool
        };

        static defaultProps = {
            value: '',
            onChange: noop,
            onBlur: noop,
            validateOnBlur: false,
            validateOnChange: false,
            validateOnMount: true,
            requiredErrorMessage: <Translation id="mandatoryField" />,
            error: '',
            isL2QLogin: false

        };

        constructor(props) {
            super(props);

            this.state = {
                value: props.value
            };
        }

        componentDidMount() {
            if (this.props.validateOnMount) {
                const { value, name } = this.props;
                const { message, valid } = this.isValid(value);

                this.props.onChange(value, name, valid, message);
                if (this.props.enableSubmit && value !== '') {
                    this.props.enableSubmit(!valid);
                }
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.value !== this.props.value) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ value: this.props.value }, () => this.handleChange(this.props.value, this.props.name));
            }
        }

        debounceValidateCall = debounce(async(valid, value, message) => {
            try {
                /* For CL We need to Verify if the number is valid apart from FE validation, To do that backend exposes this to Validate */
                if (valid) {
                    const validationRes = await this.props.validateUserEnteredNumber(value, prefixCountryCodeMap[this.props.prefix]);

                    if (validationRes.data?.errors?.message) {
                        valid = false;
                        message = validationRes.data.errors.message;
                        this.setState({
                            error: validationRes.data.errors.message,
                            value
                        });
                    }
                }
            }
            catch (e) {
                // eslint Disable next
            }
            this.props.onChange(value, this.props.name, valid, message);
        }, validateApiTimer)

        handleChange = value => {
            const { valid, message } = this.isValid(value);

            if (this.props.formatValue) {
                value = this.props.formatValue(value);
            }
            if (this.props.enableSubmit) {
                this.props.enableSubmit(!valid);
            }
            if (this.props.validateOnChange) {
                this.setState({
                    error: message,
                    value
                });
            }
            if (this.props.shouldAPIValidatePhone) {
                this.debounceValidateCall(valid, value, message);
            }

            this.props.onChange(value, this.props.name, valid, message);
        };

        handleBlur = (event, value, field) => {
            const { message, valid } = this.isValid(value || event?.target?.value);

            if (this.props.validateOnBlur) {
                this.setState({
                    error: message,
                    value
                });
            }

            this.props.onBlur(event, value || event?.target?.value, field || this.props.name, message, valid);
        };

        isValid(value) {
            const { required, requiredErrorMessage, regex, regexErrorMessage, validate, validateErrorMessage } = this.props;

            if (value) {
                if (regex && !regex.test(value)) {
                    return {
                        valid: false,
                        message: regexErrorMessage
                    };
                }
                else if (validate && !validate(value)) {
                    return {
                        valid: false,
                        message: validateErrorMessage
                    };
                }
            }
            else if (required) {
                return {
                    valid: false,
                    message: requiredErrorMessage
                };
            }
            return {
                valid: true,
                message: ''
            };
        }

        render() {
            const { value, onChange, onBlur, requiredErrorMessage, regexErrorMessage, required, regex, validateOnBlur, validateOnChange, error, ...props } = this.props; // eslint-disable-line no-unused-vars
            let errorText = error || this.state.error;

            if (errorText && typeof errorText === 'string') { // errorText (string) is shown in black and  errorText (element) is shown in red.
                errorText = [<div style={ { display: 'inline' } }>{errorText}</div>];
            }
            return (
                <Element
                    { ...props }
                    onChange={ this.handleChange }
                    onBlur={ this.handleBlur }
                    value={ this.state.value }
                    errorText={ errorText }
                />
            );
        }
    }

    const mdtp = dispatch => ({ validateUserEnteredNumber: (phone, countryCode) => dispatch(validateUserPhone(phone, countryCode)) });

    return connect(null, mdtp)(ValidatedInput);
};

export default validateElement;
