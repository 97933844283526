import { isEmpty } from 'Helpers/objects';
import {
    TYPE,
    CITIES_TYPE,
    TIME_SLOT_TYPE,
    POST_APPOINTMENT_TYPE,
    EXISTING_APPOINTMENT_TYPE,
    DISCLAIMER_TYPE,
    TIME_CENTRE_FORCEFULLY_FETCH,
    CENTRE_TIMINGS_TYPE,
    MAP_PAGE_REDUCER,
    SET_NON_SERVICEABLE_CENTER_SUGGESTION_DATA,
    SET_CITYCODE
} from './reducer';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { getSelectedLangCode } from 'Selectors/config';
import { getLocParams, getLocParamsV2 } from './helpers';
import * as selectors from './selectors';
import { FLOW_TYPES } from './constants';

const CMC_CENTRES_URL = '/api/inspection/centres';
const CMC_CENTRE_TIMINGS_URL = '/api/inspection/centre/timings';
const CMC_CENTRES_URL_V2 = '/api/v2/inspection/centres';
const CMC_CITIES_URL = '/api/inspection/cities';
const TIME_SLOTS_URL = '/api/inspection/centre/timeslots';
const POST_APPOINTMENT_URL = '/api/inspection/appointment';
const RESCHEDULE_APPOINTMENT_URL = '/api/inspection/appointment/reschedule';
const CANCEL_APPOINTMENT_URL = '/api/inspection/appointment/cancel';
const APPOINTMENT_STATUS_URL = '/api/inspection/appointment/status?confirmedState=true&full=true';
const EXISITING_APPOINTMENTS_URL = '/api/v2/inspection/user/appointments?confirmedState=true&full=true';
const DISCLAIMER_URL = '/api/rocket/configuration';

const BXP_REQUIRED_HEADER = { 'x-panamera-client-locationId': `web-${PLATFORM}` };

export const getUrlWithParams = (state, { locationId, lat, lng, type, isPPCFlow, citycode }, urlversion) => {
    let url;

    if (urlversion) {
        url = `${CMC_CENTRES_URL_V2}?lat=${lat}`;
    }
    else {
        url = `${CMC_CENTRES_URL}?locationId=${locationId}`;
    }

    if (lat && !urlversion) {
        url = `${url}&lat=${lat}`;
    }
    if (lng) {
        url = `${url}&lng=${lng}`;
    }
    if (type) {
        url = `${url}&type=${type}`;
    }

    const lang = getSelectedLangCode(state);

    if (lang) {
        url = `${url}&lang=${lang}`;
    }

    if (urlversion) {
        url = `${url}&isppcflow=${!!isPPCFlow}`;
    }

    if (citycode) {
        url = `${url}&citycode=${citycode}`;
    }

    if (state.bookAppointment?.postAppointment?.nonServiceableCenterSuggestionExpVariant) {
        url = `${url}&showNonServiceableCentreSuggestion=${!!state.bookAppointment.postAppointment.nonServiceableCenterSuggestionExpVariant}`;
    }

    return url;
};

export const fetchCentersList = selectedLocation => (dispatch, getState, { get }) => {
    const state = getState();

    const params = getLocParams(selectedLocation);
    const collection = selectors.getCityWiseCentres(state, selectedLocation);
    const defaultCentre = selectors.getDefaultCentre(state, selectedLocation);
    const isError = selectors.isError(state, selectedLocation);
    const isFetching = selectors.isFetching(state, selectedLocation);

    if (!isEmpty(collection) && !isEmpty(defaultCentre)) {
        return Promise.resolve({
            ok: true,
            data: {
                data: {
                    defaultCentre
                }
            }
        });
    }

    if (isError || isFetching) {
        return Promise.resolve();
    }

    return dispatch(get(
        CMC_CENTRES_URL,
        TYPE,
        params,
        BXP_REQUIRED_HEADER
    ));
};

export const fetchCentersListV2 = (selectedLocation, isPaidCampaign) => async(dispatch, getState, { get }) => {
    const state = getState();
    const nonServiceableCenterSuggestionExpVariant = !!(state.bookAppointment?.postAppointment?.nonServiceableCenterSuggestionExpVariant === 'b');

    const params = getLocParamsV2(selectedLocation, isPaidCampaign, nonServiceableCenterSuggestionExpVariant);
    const isError = selectors.isErrorV2(state, selectedLocation);
    const isFetching = selectors.isFetchingV2(state, selectedLocation);

    if (selectedLocation?.citycode) {
        dispatch({ type: SET_CITYCODE, citycode: selectedLocation.citycode });
    }

    if (state.bookAppointment?.centres?.nonServiceableCenterSuggestion?.length) {
        dispatch({ type: SET_NON_SERVICEABLE_CENTER_SUGGESTION_DATA, data: [] });
    }

    if (isError || isFetching) {
        return Promise.resolve();
    }

    const centreResp = await dispatch(get(
        CMC_CENTRES_URL_V2,
        TYPE,
        params,
        BXP_REQUIRED_HEADER
    ));

    if (centreResp?.data?.meta?.length) {
        dispatch({ type: SET_NON_SERVICEABLE_CENTER_SUGGESTION_DATA, data: centreResp?.data?.meta });
    }

    return centreResp;
};

export const fetchCitiesList = () => (dispatch, getState, { get }) => {
    const state = getState();

    const citiesList = state.bookAppointment.cmcCities.data.allCities;
    const isError = state.bookAppointment.cmcCities.isError;
    const isFetching = state.bookAppointment.cmcCities.isFetching;

    if (!isEmpty(citiesList) || isError || isFetching) {
        return Promise.resolve();
    }

    return dispatch(get(
        CMC_CITIES_URL,
        CITIES_TYPE,
        null,
        BXP_REQUIRED_HEADER
    ));
};

export const fetchDisclaimer = () => (dispatch, getState, { get }) => {
    const state = getState();

    const disclaimerData = state.bookAppointment.disclaimer.data;
    const isError = state.bookAppointment.disclaimer.isError;
    const isFetching = state.bookAppointment.disclaimer.isFetching;

    if (!isEmpty(disclaimerData) || isError || isFetching) {
        return Promise.resolve(disclaimerData);
    }

    return dispatch(get(
        DISCLAIMER_URL,
        DISCLAIMER_TYPE,
        {
            id: 'sell_instantly',
            version: '1.0.0',
            platform: `web-${PLATFORM}`,
            // MAKING QUICK FIX TO BY PASS AKAMAI AS IN CHILE AKAMAI IS SERVING DATA FOR MX AND HENCE SI FORM COMES FOR MX INSTEAD OF MX
            noCache: true
        }
    ));
};

export const fetchTimeSlots = (centreId, inspectionType, showRange = false) => (dispatch, getState, { get }) => {
    const params = {
        centreId,
        showRange
    };

    if (inspectionType === 0) {
        params.type = FLOW_TYPES.INSPECTION_ONLY;
    }
    else if (inspectionType === 1) {
        params.type = FLOW_TYPES.INSPECTION_HOME;
    }

    return dispatch(get(
        TIME_SLOTS_URL,
        TIME_SLOT_TYPE,
        params,
        BXP_REQUIRED_HEADER
    ));
};

export const resetTimeSlots = () => ({ type: `RESET_${TIME_SLOT_TYPE}` });

export const resetCenters = () => ({ type: `RESET_${TYPE}` });

export const resetLocationDetails = () => ({ type: MAP_PAGE_REDUCER.RESET_LOCATION_DETAILS });

export const postAppointment = (params, options) => (dispatch, getState, { post }) => {
    return dispatch(post(
        options.isReschedule ? RESCHEDULE_APPOINTMENT_URL : POST_APPOINTMENT_URL,
        POST_APPOINTMENT_TYPE,
        params
    ));
};

export const fetchAppointment = bookingId => (dispatch, getState, { post }) => {
    const params = {
        bookingId
    };

    return dispatch(post(
        APPOINTMENT_STATUS_URL,
        POST_APPOINTMENT_TYPE,
        params
    ));
};

export const cancelAppointment = params => (dispatch, getState, { post }) => {
    return dispatch(post(
        CANCEL_APPOINTMENT_URL,
        POST_APPOINTMENT_TYPE,
        params
    ));
};

export const fetchUserAppointments = () => (dispatch, getState, { get }) => {
    return dispatch(get(
        EXISITING_APPOINTMENTS_URL,
        EXISTING_APPOINTMENT_TYPE
    ));
};

export const fetchCenterTimings = centreId => (dispatch, getState, { get }) => {
    const state = getState();
    const params = {
        centreId,
        type: 'INSPECTION'
    };

    const centreTimings = state.bookAppointment.centreTimings.data[centreId];
    const isError = state.bookAppointment.centreTimings.isError[centreId];
    const isFetching = state.bookAppointment.centreTimings.isFetching[centreId];

    if (!isEmpty(centreTimings) || isError || isFetching) {
        return Promise.resolve();
    }
    return dispatch(get(
        CMC_CENTRE_TIMINGS_URL,
        CENTRE_TIMINGS_TYPE,
        params,
        BXP_REQUIRED_HEADER
    ));
};

export const clearAppointmentDetails = () => ({ type: 'CLEAR-BOOKING' });

export const updateFetchTimeCentreForceFully = val => ({ type: TIME_CENTRE_FORCEFULLY_FETCH, data: val });

export const setNonServiceableCenterSuggestionExpVariant = variant => ({ type: 'SET_NON_SERVICEABLE_CENTER_SUGGESTION', variant });
