/* eslint-disable callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* global navigator window*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    authenticate as authenticateAction,
    authenticateLogin as authenticateLoginAction,
    getLoggedUserData as getLoggedUserDataAction,
    createChallenge as createChallengeAction,
    validateChallenge as validateChallengeAction,
    linkAccount as linkAccountAction
} from 'Actions/user';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';
import { noop } from 'Helpers/function';
import Auth from '../../Auth';
import cookieManagerHelper from 'Helpers/cookies';
import { LoginTrackingKeys } from 'Constants/tracking';
import { LOGIN_GRANT_TYPE, ACTIONS, STATUS } from 'Constants/authentication';
import { handleUserObject } from 'Helpers/cleverTap';
import { configSelector } from 'Selectors/config';
import { userAuthenticateData as userAuthenticateDataSelector } from 'Selectors/user';
import { userConsentsScreenShow } from 'Helpers/userConsents';
import {
    API_NAME,
    FLOW_TYPE,
    TRACKING_PARAMS,
    ERROR_TYPE,
    trackedLoginMethods
} from 'Constants/leadOtpWrapper';
import { isMobile } from 'Helpers/devices';
import { withExperiment } from 'LaquesisEOL/withExperiment';
import experiment from 'Components/Login/LegionLoginExperiment.APP_TARGET';
import { getBrowserDetails } from 'Helpers/browserDetails';
import withTrack from 'HOCs/withTrack/withTrack';
import { getConsents, getUserLoginDetails, handleAuthError, mapError } from 'Helpers/leadOtpWrapper';

const errorsKeys = {
    user_banned: <Translation id="login_user_banned" />,
    unauthorized: <Translation id="login_unauthorized" />,
    invalid_token: <Translation id="login_invalid_token" />,
    invalid_password: <Translation id="login_invalid_password" />,
    invalid_phone: <Translation id="login_invalid_phone" />,
    expired_token: <Translation id="login_expired_token" />,
    invalid_user: <Translation id="login_invalid_user" />,
    too_many_requests: <Translation id="login_too_many_requests" />,
    unknown_error: <Translation id="login_unknown_error" />,
    MAX_ATTEMPTS_REACHED: <Translation id="login_too_many_requests" />
};

const LeadOtpWrapper = props => {
    const {
        intl,
        descriptor,
        onSuccess,
        onError,
        onApiSuccess,
        render,
        userAuthenticateData,
        isMarketingConsents,
        trackingProps,
        variant,
        track
    } = props;
    const { auth, authToken } = userAuthenticateData;
    const [showLoader, setShowLoader] = useState(false);

    const enableConsentsInLoginFlow = !!props.marketConfig.get('enableConsentsInLoginFlow');
    const configConsentsData = props.config?.users?.consentData?.consents || [];
    const shouldCallV3API = enableConsentsInLoginFlow && !!configConsentsData.length;
    const OTPAutoDetectionPossible
        = variant === 'b'
        && props.marketConfig.get('autoReadOTP')
        && 'OTPCredential' in window
        && isMobile;
    const flowType = Auth.isLoggedIn() ? FLOW_TYPE.LINK_PHONE : FLOW_TYPE.LOGIN_REGISTER;
    const isLinkPhoneFlow = flowType === FLOW_TYPE.LINK_PHONE;
    const trackingPayload = {
        select_from: trackingProps.ctaName,
        flow_step: trackingProps.flowStep,
        ...trackingProps.adpFinanceTabTrackingInfo
    };

    useEffect(() => {
        if (isLinkPhoneFlow) {
            createChallengeFunc(descriptor, LOGIN_GRANT_TYPE.phone, true);
        }
        else {
            const params = {
                grantType: LOGIN_GRANT_TYPE.forcePhonePin,
                phone: descriptor
            };

            authenticateFunc(params, true);
        }
    }, []);

    const trackFunc = (event, payload = {}) => {
        let newPayload = { ...payload, ...trackingProps };

        if (event === LoginTrackingKeys.LOGIN_SIGN_IN_COMPLETE) {
            newPayload = getUserLoginDetails(newPayload);
        }
        props.track(event, newPayload);
    };

    const trackSendData = (nextAction, status, params) => {
        /* istanbul ignore else */
        if (
            !!nextAction
            && status === STATUS.PENDING
            && trackedLoginMethods.includes(params.grantType)
        ) {
            trackFunc(LoginTrackingKeys.LOGIN_SEND_DATA, {
                login_method: params.grantType,
                select_from: nextAction.toLowerCase()
            });
        }
    };

    const trackLoginError = errorType => {
        if (errorType === ERROR_TYPE.USER_BANNED) {
            trackFunc(LoginTrackingKeys.LOGIN_ERRORS, {
                error_message: errorType,
                error_origin: TRACKING_PARAMS.FIND_USER_SCREEN
            });
        }
    };

    const trackSignInComplete = (status, params, autoReadOTPScreen = false) => {
        const { AUTO_OTP, MANUAL, REGISTER, LOGIN, BROWSER_DETAILS } = TRACKING_PARAMS;

        /* istanbul ignore else */
        if (status === STATUS.GRANTED) {
            if (OTPAutoDetectionPossible && params.phone) {
                trackFunc(LoginTrackingKeys.LOGIN_SIGN_IN_COMPLETE, {
                    login_method: LOGIN_GRANT_TYPE.phone,
                    select_from: autoReadOTPScreen ? AUTO_OTP : MANUAL,
                    login_type: authToken?.is_new_user ? REGISTER : LOGIN
                });
            }
            else {
                trackFunc(LoginTrackingKeys.LOGIN_SIGN_IN_COMPLETE, {
                    login_method: params.phone && LOGIN_GRANT_TYPE.phone,
                    select_from: `${params.grantType.toLowerCase()}_screen`,
                    login_type: authToken?.is_new_user ? REGISTER : LOGIN
                });
            }

            /* istanbul ignore else */
            if (isMobile && params.phone) {
                trackFunc(BROWSER_DETAILS, {
                    login_type: authToken?.is_new_user ? REGISTER : LOGIN,
                    chosen_option: getBrowserDetails(),
                    select_from: navigator.userAgent
                });
            }
        }
    };

    const trackSignInFailed = (errorType, params, autoReadOTPScreen = false) => {
        const { AUTO_OTP, MANUAL, PIN_SCREEN } = TRACKING_PARAMS;

        if (OTPAutoDetectionPossible && params.phone) {
            trackFunc(LoginTrackingKeys.LOGIN_ERRORS, {
                error_message: errorType,
                chosen_option: autoReadOTPScreen ? AUTO_OTP : MANUAL,
                error_origin: autoReadOTPScreen ? AUTO_OTP : PIN_SCREEN,
                login_method: LOGIN_GRANT_TYPE.phone
            });
        }
        else {
            trackFunc(LoginTrackingKeys.LOGIN_ERRORS, {
                error_message: errorType,
                error_origin: `${params.grantType.toLowerCase()}_screen`
            });
        }
    };

    const shouldAddConsent = () => {
        /* istanbul ignore else */
        if (enableConsentsInLoginFlow) {
            /* istanbul ignore else */
            if (!configConsentsData.length) {
                return false;
            }
            /* istanbul ignore next */
            const userConsentsData = auth?.consents || {};

            if (auth?.nextAction === ACTIONS.PIN || auth?.nextAction === ACTIONS.PASSWORD) {
                if (authToken?.is_new_user) {
                    return true;
                }
                return userConsentsScreenShow(configConsentsData, userConsentsData);
            }
        }
        return false;
    };

    const getUserCallback = res => {
        setShowLoader(false);
        onSuccess(res);
        handleUserObject(res);
        setShowLoader(false);
    };

    const linkAccountFunc = token => {
        props.linkAccount('challenger', token, res => {
            /* istanbul ignore else */
            if (res.data) {
                if (res.data.error) {
                    const error = handleAuthError(res, props.intl);

                    onError(res, API_NAME.LINK_PHONE, error);
                    setShowLoader(false);
                }
                else {
                    onApiSuccess(res, API_NAME.LINK_PHONE);
                    props.getUser(true, getUserCallback);
                }
            }
        });
    };

    const validateChallengeFunc = (descriptor, code) => {
        const type = LOGIN_GRANT_TYPE.phone;

        setShowLoader(true);

        props.validateChallenge(type, descriptor, code, res => {
            if (res.data.error) {
                const error = handleAuthError(res, props.intl);

                trackFunc('login_errors', {
                    login_method: type,
                    login_type: 'login',
                    error_message: error,
                    error_origin: 'validateChallenge'
                });
                onError(res, API_NAME.VALIDATE_OTP, error);
                setShowLoader(false);
                track(LoginTrackingKeys.PHONE_OTP_SUBMIT_FAILED, trackingPayload);
            }
            else {
                linkAccountFunc(res?.data?.data?.token);
                onApiSuccess(res, API_NAME.VALIDATE_OTP);

                track(LoginTrackingKeys.PHONE_OTP_SUBMIT_SUCCESS, trackingPayload);
            }
        });
    };

    const authenticateLoginFunc = (params, showLoader = false) => {
        setShowLoader(showLoader);
        params.language = props.language;
        props.authenticateLogin(
            params,
            async res => {
                if (res?.ok && res.data) {
                    props.getUser(true, getUserCallback);
                    onApiSuccess(res, API_NAME.LOGIN);

                    track(LoginTrackingKeys.PHONE_OTP_SUBMIT_SUCCESS, trackingPayload);
                    // TODO: auto read otp flow
                    // if (autoReadOTPScreen && this.OTPAutoDetectionPossible && !!params.phone) {
                    //     setTimeout(async () => {
                    //         this.trackSignInComplete(res.data.status, params, autoReadOTPScreen);
                    //         await this.updateFlow();
                    //     }, this.successWaitInterval);
                    // }
                    trackSignInComplete(res.data.status, params);
                }
                else {
                    trackSignInFailed(res?.data?.errorType, params);
                    const errorMsg = mapError(res, errorsKeys);

                    onError(res, API_NAME.LOGIN, errorMsg);
                    setShowLoader(false);

                    track(LoginTrackingKeys.PHONE_OTP_SUBMIT_FAILED, trackingPayload);
                }
            },
            shouldCallV3API
        );
    };

    const handleSkip = () => {
        onSuccess();
    };

    const handleSubmit = ({ values }) => {
        if (isLinkPhoneFlow) {
            validateChallengeFunc(descriptor, values.code);
        }
        else {
            const params = {
                grantType: LOGIN_GRANT_TYPE.pin,
                code: values.code,
                phone: descriptor
            };

            /* istanbul ignore else */
            if (shouldAddConsent()) {
                params.consents = getConsents(configConsentsData, isMarketingConsents);
            }
            params.language = props.language;

            authenticateLoginFunc(params, true);
        }
    };

    /**
     * Send OTP to the user's phone
     * @param {Number} descriptor user phone number
     * @param {String} type Login method, ex: phone | email
     * @param {Boolean} showLoader Whether to show loader before API call
     * @param {String} action Action type - SENT_OTP | RESEND_OTP
     */
    function createChallengeFunc(descriptor, type, showLoader = false, action = API_NAME.SEND_OTP) {
        setShowLoader(showLoader);
        props.createChallenge(type, descriptor, async res => {
            if (res.ok) {
                onApiSuccess(res, action);
            }
            else {
                /* istanbul ignore else */
                if (isLinkPhoneFlow) {
                    const error = handleAuthError(res, props.intl) || intl.formatMessage({ id: 'error_title' });

                    trackFunc(LoginTrackingKeys.LOGIN_ERRORS, {
                        login_method: type,
                        login_type: 'login',
                        error_message: error,
                        error_origin: 'createChallenge'
                    });
                }
                const errorMsg = mapError(res, errorsKeys);

                onError(res, API_NAME.SEND_OTP, errorMsg);
            }
            setShowLoader(false);
        });
    }

    function authenticateFunc(params, showLoader = false, action = API_NAME.AUTHENTICATE) {
        setShowLoader(showLoader);
        params.language = props.language;
        props.authenticate(
            params,
            async res => {
                if (res?.ok && res.data) {
                    trackSendData(res.data.nextAction, res.data.status, params);
                    // User cookie should be deleted if created due to legion enabled auth api in the middleware to stop user from appearing to be logged-in
                    // Otherwise, while handling UNAUTHORIZED_FETCH error for this api, the user will appear to be logged in for Auth.isLoggedIn() function
                    // This can lead to unknown_error scenarios
                    /* istanbul ignore else */
                    if (Auth.getCookie('user')) {
                        cookieManagerHelper.eraseCookie('user');
                    }
                    onApiSuccess(res, action);
                    // TODO: update this logic after completion of ROAD-36776
                    /* istanbul ignore else */
                    if (shouldCallV3API) {
                        createChallengeFunc(descriptor, LOGIN_GRANT_TYPE.phone);
                    }
                }
                else {
                    trackLoginError(res.data?.errorType);
                    const errorMsg = mapError(res, errorsKeys);

                    onError(res, API_NAME.AUTHENTICATE, errorMsg);
                }
                setShowLoader(false);
            },
            shouldCallV3API
        );
    }

    /**
     * Send OTP to the user
     * @param {Number} intents No. of OTP send request
     * @param {String} method Request OTP via call, phone etc.
     */
    const resendOtp = (intents, method) => {
        track(LoginTrackingKeys.PHONE_RESEND_CODE, trackingPayload);

        if (shouldCallV3API) {
            createChallengeFunc(descriptor, LOGIN_GRANT_TYPE.phone, null, API_NAME.RESEND_OTP);
        }
        else {
            const params = {
                grantType: LOGIN_GRANT_TYPE.retry,
                method,
                phone: descriptor
            };

            authenticateFunc(params, true, API_NAME.RESEND_OTP);
        }

        const payload = {
            login_method: LOGIN_GRANT_TYPE.phone,
            select_from: method,
            ...(isLinkPhoneFlow ? { intents } : {})
        };

        props.track(LoginTrackingKeys.LOGIN_RESEND_CODE, payload);
    };

    return (
        <>
            {render({
                onResendOtp: resendOtp,
                onSubmitOtp: handleSubmit,
                skipOtpVerification: handleSkip,
                showLoader
            })}
        </>
    );
};

LeadOtpWrapper.propTypes = {
    descriptor: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onApiSuccess: PropTypes.func,
    render: PropTypes.func.isRequired,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    authenticate: PropTypes.func.isRequired,
    authenticateLogin: PropTypes.func.isRequired,
    createChallenge: PropTypes.func.isRequired,
    language: PropTypes.string,
    getUser: PropTypes.func.isRequired,
    validateChallenge: PropTypes.func.isRequired,
    linkAccount: PropTypes.func.isRequired,
    config: PropTypes.object,
    userAuthenticateData: PropTypes.object,
    isMarketingConsents: PropTypes.bool,
    variant: PropTypes.string,
    intl: PropTypes.object.isRequired,
    track: PropTypes.func.isRequired,
    trackingProps: PropTypes.object
};

LeadOtpWrapper.defaultProps = {
    createChallenge: noop,
    onSuccess: noop,
    onError: noop,
    onApiSuccess: noop,
    config: {},
    userAuthenticateData: {},
    isMarketingConsents: false,
    trackingProps: {}
};

const mapStateToProps = (state, { marketConfig }) => {
    return {
        language: marketConfig.get('lang'),
        config: configSelector(state),
        userAuthenticateData: userAuthenticateDataSelector(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        authenticate: (params, callback = noop, isV3) => dispatch(authenticateAction(params, isV3)).then(res => callback(res)),
        authenticateLogin: (params, callback = noop, isV3) => dispatch(authenticateLoginAction(params, isV3)).then(res => callback(res)),
        createChallenge: (type, descriptor, callback = noop) => dispatch(createChallengeAction(type, descriptor)).then(res => callback(res)),
        getUser: (forceFetch, callback) => dispatch(getLoggedUserDataAction(forceFetch)).then(res => callback(res)),
        validateChallenge: (type, descriptor, secret, callback) => dispatch(validateChallengeAction(type, descriptor, secret)).then(res => callback(res)),
        linkAccount: (type, token, callback) => dispatch(linkAccountAction(type, token)).then(res => callback(res))
    };
};

export default compose(
    withTrack,
    withExperiment(experiment),
    withConfig('marketConfig'),
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(LeadOtpWrapper);
