/* eslint-disable max-lines */
/* global window navigator */
import { queryStringToParams } from 'Helpers/url';
import { createSelector } from 'reselect';
import { UserSession } from 'olx-autos-landing-page';
import { FIELD_CONSTANTS, SHOW_ALL_BTN_KEY, API_PATH } from '../ValuationFlow/constants';
import uaParserJs from 'ua-parser-js';
import { paramsToQueryString } from 'Helpers/url';
import { REVAMP_URLS } from '../Inspection/constants';
import { ADDRESS_TYPE, BOOKING_STATES } from 'Autos/BookAppointmentV2/constants';
import { VALUATION_TRACKING_EVENTS } from 'Constants/sellCarO2O';
import { supportLocalStorage } from 'Helpers/localStorage';
import { ALLOWED_TYPES, Q2B_FLOWS } from 'Autos/constants';
import SITE_CODES from 'Constants/siteCodes';

export const getBrowser = () => {
    if (navigator?.userAgent) {
        const parser = uaParserJs(navigator.userAgent);

        return parser?.browser?.name ?? '';
    }

    return '';
};

export const checkIfMxCL = marketConfig => {
    const olxAutos = marketConfig.get('olxAutos');

    return !!(olxAutos && olxAutos.isMXCL);
};

export const getOlxAutosConfigProperty = (marketConfig, property) => {
    const olxAutos = marketConfig.get('olxAutos');

    return olxAutos && olxAutos[property];
};

export const getTrackingName = (name, isCamelCase) => {
    name = name.split('_');
    let newName = '';
    const iValue = isCamelCase ? 1 : 2;

    for (let i = 0; i < name.length; i++) {
        newName += i < iValue ? name[i] : name[i].charAt(0).toUpperCase() + name[i].slice(1).toLowerCase();
    }
    return newName;
};

export const getCampaignParams = createSelector(
    landingURL => landingURL,
    (_, isCamelCase) => isCamelCase,
    (landingURL, isCamelCase) => {
        const utmParams = { utm_source: true, utm_medium: true, utm_campaign: true, utm_term: true, utm_content: true, gclid: true, utm_campaign_id: true };
        let campaignUrl = landingURL;
        const searchParamsObj = {};

        if (campaignUrl) {
            if (campaignUrl[0] === '?') {
                campaignUrl = campaignUrl.slice(1);
            }
            const paramsObj = queryStringToParams(campaignUrl);

            for (const key in paramsObj) {
                if (utmParams[key]) {
                    searchParamsObj[`quote_${getTrackingName(key, isCamelCase)}`] = paramsObj[key];
                }
            }
        }
        return searchParamsObj;
    }
);

export const getUpdatedTrim = resultSet => {
    resultSet[FIELD_CONSTANTS.TRIM_KEY] = resultSet[FIELD_CONSTANTS.VARIANT_KEY];
    delete resultSet[FIELD_CONSTANTS.VARIANT_KEY];
    return resultSet;
};

export const getInitialFormFieldsData = (formFields, intl) => {
    if (!formFields.length) {
        return null;
    }
    const phoneObject = {
        component: 'Text',
        id: FIELD_CONSTANTS.PHONE_KEY,
        label: intl.formatMessage({ id: 'details' }),
        required: true,
        key: FIELD_CONSTANTS.PHONE_KEY,
        labelDesc: intl.formatMessage({ id: 'enterContactDetails' })
    };
    const formFieldsCopy = [...formFields];

    formFieldsCopy.push(phoneObject);
    return formFieldsCopy;
};

export const getInitialCarAttributeData = (carAttributes, intl) => {
    if (!carAttributes?.popular_list) {
        return null;
    }
    const SHOW_ALL_BTN = {
        key: SHOW_ALL_BTN_KEY,
        label: intl.formatMessage({ id: SHOW_ALL_BTN_KEY }),
        icon: ''
    };
    const carAttributesCopy = { ...carAttributes };

    const popularListCopy = [...carAttributesCopy.popular_list];

    // eslint-disable-next-line no-magic-numbers
    if (carAttributesCopy.popular_list.length === 9) {
        return { [carAttributes.id]: carAttributesCopy };
    }

    if (carAttributesCopy.popular_list.length) {
        popularListCopy.push(SHOW_ALL_BTN);
        carAttributesCopy.popular_list = popularListCopy;
    }

    return { [carAttributes.id]: carAttributesCopy };
};

export const sanitizeLeadGenPayload = (userDataValues = {}, marketConfig, flowType, landingURLSearch, showWhatsApp, allowWhatsApp, numberPlate, includeLeadId = false) => {
    const {
        DISPLAY_VALUE,
        PHONE_KEY,
        MILEAGE_KEY,
        VARIANT_KEY,
        REGISTERED_STATE_KEY,
        AREA_KEY,
        SELL_URGENCY_KEY
    } = FIELD_CONSTANTS;
    let result = {};
    const phonePrefix = marketConfig.get('lead_phone_prefix');
    const displayValueRegEx = new RegExp(`^(.*)-${DISPLAY_VALUE}$`);

    for (const key in userDataValues) {
        const match = displayValueRegEx.exec(key);

        if (match && match.length >= 2 && match[1]) {
            result[match[1]] = userDataValues[key];
        }
    }

    if (FIELD_CONSTANTS.PHONE_KEY in result && phonePrefix) {
        result[PHONE_KEY] = `${phonePrefix}${result[PHONE_KEY]}`;
    }

    if (MILEAGE_KEY in result) {
        const newMileageValue = result[MILEAGE_KEY].split('-');

        if (newMileageValue && newMileageValue.length >= 2) {
            result[MILEAGE_KEY] = newMileageValue[1];
        }
    }

    if (VARIANT_KEY in result) {
        result = getUpdatedTrim(result);
    }

    if (REGISTERED_STATE_KEY in result) {
        result[AREA_KEY] = result[REGISTERED_STATE_KEY];
        delete result[REGISTERED_STATE_KEY];
    }

    if (SELL_URGENCY_KEY in result) {
        result[SELL_URGENCY_KEY] = userDataValues[SELL_URGENCY_KEY];
    }

    if (flowType) {
        result.flowType = flowType;
    }
    if (showWhatsApp) {
        result.whatsapp = allowWhatsApp;
    }

    // SEND LEAD_ID IN PARAMS TO UPDATE THE EXISTING LEAD
    const leadId = window.localStorage.getItem(FIELD_CONSTANTS.LEAD_ID) || '';

    if (includeLeadId && leadId) {
        result.leadId = leadId;
    }

    result.inspectionType = userDataValues.inspectionType || '';
    result.campaign = userDataValues.campaign || 'Quote';

    if (typeof window !== 'undefined' && !result.plate) {
        result.plate = window.localStorage.getItem('numberPlate') || numberPlate;
    }
    return { ...result, ...getCampaignParams(landingURLSearch) };
};

export const getTargetUrl = (url, queryParamsToAdd = {}, options = {}, location) => {
    if (!options.removeQueryParams) {
        const queryParams = paramsToQueryString({
            ...location.query,
            ...queryParamsToAdd
        });

        if (queryParams) {
            url = `${url}?${queryParams}`;
        }
    }
    return url;
};

export const goToUrl = (url, queryParamsToAdd, options = {}, router) => {
    const targetUrl = getTargetUrl(url, queryParamsToAdd, options, router.location);

    if (options.shouldReplace) {
        router.replace(targetUrl);
        return;
    }

    router.push(targetUrl);
};

export const getRevampURL = type => REVAMP_URLS[type];

export function getTrackCarParams(data) {
    const params = {};

    if (data) {
        params.item_make = data[`${FIELD_CONSTANTS.MAKE_KEY}`];
        params.item_model = data[`${FIELD_CONSTANTS.MODEL_KEY}`];
        params.item_variant = data[`${FIELD_CONSTANTS.VARIANT_KEY}`];
        params.item_year = data[`${FIELD_CONSTANTS.YEAR_KEY}`];
        params.item_mileage = data[`${FIELD_CONSTANTS.MILEAGE_KEY}`];
        params.item_num_owners = data[`${FIELD_CONSTANTS.FIRST_OWNER}`];
        params.item_registeredstate = data[`${FIELD_CONSTANTS.REGISTERED_STATE_KEY}`];
        params.item_transmission = data[`${FIELD_CONSTANTS.TRANSMISSION}`];
        params.item_bodytype = data[`${FIELD_CONSTANTS.BODY_TYPE}`];
        params.item_facelift = data[`${FIELD_CONSTANTS.FACE_LIFT}`];
        params.item_seat = data[`${FIELD_CONSTANTS.SEAT_TYPE}`];
        params.item_engine = data[`${FIELD_CONSTANTS.ENGINE_TYPE}`];
        params.item_wheeldrive = data[`${FIELD_CONSTANTS.WHEEL_DRIVE}`];
        params.item_sellingurgency = data[`${FIELD_CONSTANTS.SELL_URGENCY_KEY}`];
    }
    return params;
}

export const getMapAddressLine = mapAddressLine => {
    if (mapAddressLine.length) {
        const addresses = mapAddressLine.split(',');

        return { address1: addresses[0], address2: addresses.length > 1 ? addresses.slice(1).join('') : '' };
    }
    return {};
};

export const getAPIPathName = (formFields, apiParam) => {
    let apiPath = `${API_PATH.GET_VALUES}/${apiParam}`;

    if (formFields?.length) {
        const curIdx = formFields.findIndex(fields => fields.id === apiParam);

        if (curIdx >= 0) {
            apiPath = formFields[curIdx].valueUrl;

            if (apiPath.indexOf('v1') > -1) {
                apiPath = apiPath.replace('/v1', '');
            }
        }
    }
    return apiPath;
};

export const getFormatedAddress = addressComponents => {
    const neighbourhoodLoc = addressComponents.find(loc => loc.type === ADDRESS_TYPE.NEIGHBOURHOOD);

    const order = [ADDRESS_TYPE.NEIGHBOURHOOD, ADDRESS_TYPE.CITY, ADDRESS_TYPE.STATE, ADDRESS_TYPE.COUNTRY];
    const sortByObject = order
        .reduce((obj, item, index) => {
            return {
                ...obj,
                [item]: index
            };
        }, {});

    const customSort = addressComponents.sort((a, b) => sortByObject[a.type] - sortByObject[b.type]);

    const fullAddress = customSort.reduce((prev, curr) => {
        return prev ? `${prev}, ${curr.name}` : `${curr.name}`;
    }, '');

    return ({
        neighbourhoodLoc: neighbourhoodLoc || customSort?.[0],
        fullAddress
    });
};

export const getCurrentAddress = (selectedAddress, selectedUserLocation, updateSelectedMapAddress) => {
    if (selectedAddress && selectedAddress.length > 0) {
        const { fullAddress } = getFormatedAddress(selectedAddress);

        const [street, ...rest] = fullAddress.split(',');

        updateSelectedMapAddress?.(fullAddress);

        return ({
            street,
            region: rest.join(', ')
        });
    }

    return ({
        street: selectedUserLocation.address1 || selectedUserLocation.name,
        region: selectedUserLocation.address2 || selectedUserLocation.name
    });
};

export const handleLocalStorageOnConfirmation = data => {
    if (data.status === BOOKING_STATES.CONFIRMED) {
        UserSession.deleteEvaluationPredictedPrice();
        UserSession.deleteEvaluationFormValues();
    }
    UserSession.saveAppointmentDetails(data);

    if (supportLocalStorage()) {
        window.localStorage.removeItem(FIELD_CONSTANTS.LAST_SELECTED_SECTION);
        window.localStorage.removeItem(VALUATION_TRACKING_EVENTS.field.bookingIndexId);
    }
};

export const getAddressByLatLong = async (latitude, longitude, loader = () => {}) => {
    if (!window.google?.maps) {
        return;
    }

    loader(true);

    const geocoder = new window.google.maps.Geocoder();

    try {
        const res = await geocoder.geocode({ location: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
        }});

        let formatted_address = res?.results[0]?.formatted_address;
        let mainAddress = formatted_address.split(',')[0];

        // GET ALL THE ADDRESS TYPES
        const addressTypes = {};

        res?.results[0]?.address_components?.forEach(addressComponent => {
            addressComponent.types.forEach(type => {
                if (!addressTypes[type]) {
                    addressTypes[type] = addressComponent.long_name;
                }
            });
        });

        // Find locality node to get city of selected location
        const locatity = res.results.find(address => address.types.includes('locality'));

        // BASED ON THE ALLOWED_TYPES, SET MAIN ADDRESS
        for (let i = 0; i < ALLOWED_TYPES.length; i++) {
            if (addressTypes[ALLOWED_TYPES[i]]) {
                mainAddress = addressTypes[ALLOWED_TYPES[i]];
                break;
            }
        }

        // REMOVE THE MAIN ADDRESS FROM THE FORMATTED ADDRESS
        formatted_address = formatted_address.replace(`${mainAddress },`, '');

        // eslint-disable-next-line consistent-return
        return ({
            formatted_address,
            mainAddress,
            citycode: locatity?.address_components[0].long_name,
            res
        });
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
    finally {
        loader(false);
    }
};

export const navigateToUrl = (url, navigate, searchParams, queryParamsToAdd = {}, removeQueryParams = false) => {
    if (!removeQueryParams) {
        const queryParams = paramsToQueryString({
            ...Object.fromEntries([...searchParams]),
            ...queryParamsToAdd
        });

        if (queryParams) {
            url = `${url}?${queryParams}`;
        }
    }

    navigate(url);
};

export const trimUrlParams = params => {
    if (params?.length) {
        const trimParams = params.split('&');

        trimParams?.shift();
        params = trimParams?.join('&');
        return `&${params}`;
    }
    return '';
};

// FORMAT PHONE NUMBER FOR TURKEY
export function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
        const STATE_CODE_LENGTH = 3;

        return `${phoneNumber?.substr(0, STATE_CODE_LENGTH)} ${phoneNumber?.substr(STATE_CODE_LENGTH)}`;
    }

    return '';
}
export const sanitizePayload = (displayFields, lastSelectedSection, payload) => {
    const selectedFieldObj = displayFields.find(item => item.id === lastSelectedSection);

    if (selectedFieldObj) {
        let indexOfSelectedField = displayFields.indexOf(selectedFieldObj);
        const sanitizedPayload = payload;

        while (indexOfSelectedField < (displayFields.length - 1)) {
            indexOfSelectedField++;

            const selectedDisplayField = displayFields[indexOfSelectedField];
            const key = selectedDisplayField.id;

            delete sanitizedPayload[key];
            delete sanitizedPayload[`${key}Value`];
        }
        return sanitizedPayload;
    }
    return payload;
};

export const getQ2BFlow = ({ onePageCheckoutVariant, mapPageExperiment, isMobile }, marketConfig) => {
    const siteCode = marketConfig.get('siteCode');
    const onePageCheckout = marketConfig.get('olxAutos', 'onePageCheckout');
    const isRevampFlow = marketConfig.get('olxAutos', 'isRevampFlow');
    const isOpcFlow = (onePageCheckoutVariant === 'b' || onePageCheckout?.enableDefault) && isMobile;

    const partialRevampFlow = [SITE_CODES.OLX_TR, SITE_CODES.OLX_CL].includes(siteCode);
    const showRevampFlowOnlyDesktop = [SITE_CODES.OLX_TR].includes(siteCode);

    if (isOpcFlow) {
        return Q2B_FLOWS.OPC;
    }
    else if (isRevampFlow && mapPageExperiment === 'b') {
        if ((!isMobile && showRevampFlowOnlyDesktop) || !showRevampFlowOnlyDesktop) {
            return Q2B_FLOWS.REVAMP;
        }

        return Q2B_FLOWS.OLD;
    }
    else if (isRevampFlow && !partialRevampFlow) {
        return Q2B_FLOWS.REVAMP;
    }

    return Q2B_FLOWS.OLD;
};

export const getStoreDistanceText = (distance, intl) => {
    return distance > 1 ? intl.formatMessage({ id: 'approxDistKMS' }, { distance }) : intl.formatMessage({ id: 'approxDistKM' }, { distance });
};
