import { FLOW_REDUCERS_TYPE, API_PATH, VALUATION_FORM_FIELDS, PANAMERA_CLIENT, VALUATION_FORM_DATA_VALUES, FIELD_CONSTANTS, PRICE_API_VERSION, VALUATION_FORM_REGISTRATION } from './constants';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { isEmpty } from 'lodash';

export const toggleShowAllCTA = flag => ({ type: FLOW_REDUCERS_TYPE.UPDATE_SHOW_ALL_HP, value: flag });

export const updateRegistrationUrlApiCalled = value => ({ type: FLOW_REDUCERS_TYPE.IS_REGISTRATION_API_CALLED, value });

export const fetchFormFields = apiVersion => (dispatch, getState, { get }) => {
    const { valuationFormFields } = getState().valuationFlow;
    let apiPath = API_PATH.FORM_FIELDS;

    if (apiVersion) {
        apiPath += `?version=${apiVersion}`;
    }

    if (valuationFormFields.length) {
        return Promise.resolve();
    }

    return dispatch(get(apiPath, VALUATION_FORM_FIELDS, {}, PANAMERA_CLIENT));
};

export const fetchMakeValues = () => (dispatch, getState, { get }) => {
    const { valuationMakePairs } = getState().valuationFlow;

    if (valuationMakePairs) {
        return Promise.resolve();
    }

    return dispatch(get(`${API_PATH.GET_VALUES}/${FIELD_CONSTANTS.MAKE_KEY}?platform=web-${PLATFORM}&version=${PRICE_API_VERSION}`, `${VALUATION_FORM_DATA_VALUES}_${FIELD_CONSTANTS.MAKE_KEY.toUpperCase()}`, {}, PANAMERA_CLIENT));
};

export const fetchCarDetails = params => (dispatch, _, { get }) => {
    if (isEmpty(params)) {
        return Promise.resolve();
    }
    return dispatch(get(API_PATH.REGISTRATION_DETAILS, VALUATION_FORM_REGISTRATION, params, PANAMERA_CLIENT));
};

export const getDataValues = (apiPath, userDataValues) => (dispatch, getState, { get }) => {
    const { valuationMakePairs } = getState().valuationFlow;

    if (!apiPath || isEmpty(userDataValues) || valuationMakePairs) {
        return Promise.resolve();
    }

    return dispatch(get(`${apiPath}?platform=web-${PLATFORM}&version=${PRICE_API_VERSION}`, `${VALUATION_FORM_DATA_VALUES}_${FIELD_CONSTANTS.MAKE_KEY.toUpperCase()}`, userDataValues, PANAMERA_CLIENT));
};
