/**
 * It is called dynamically from buildSchema()
 * @param props
 * @returns {{'@context': 'https://schema.org', '@type': 'FAQPage', mainEntity: [{'@type': 'Question', name: string, acceptedAnswer: {'@type': 'Answer', text: string}}}
 */
const buildFAQSchema = ({ content }) => {
    if (!content) {
        return null;
    }

    const mainEntity = content?.map(({ q: name, a: text }) => ({
        '@type': 'Question',
        name,
        acceptedAnswer: {
            '@type': 'Answer',
            text
        }
    }));

    return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity
    };
};

export default buildFAQSchema;
