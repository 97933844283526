import React from 'react';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import css from './ErrorMessage.scss';

const ErrorMessage = ({ intl }) => {
    return (
        <div className={ css.errorWrapper }>
            <IconWrapper
                icon="info"
                color="redBase"
                size={ 20 }
                className={ css.icon }
            />
            {intl.formatMessage({ id: 'information_error' })}
        </div>
    );
};

ErrorMessage.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired
};

export default injectIntl(React.memo(ErrorMessage));
