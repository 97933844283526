import { useMemo } from 'react';

import { omitUndefinedKeys } from 'Helpers/objects';

export const useDealerProps = dealer => {
    const dealerProps = useMemo(() => {
        if (!dealer) {
            return {};
        }

        const dealerObj = { dealerId: dealer.id };

        return omitUndefinedKeys(dealerObj);
    }, [dealer]);

    return dealerProps;
};
