import { arrayToObject } from 'Helpers/array';
import { getNestedValue, isObject } from 'Helpers/objects';
import { COMPONENT_TYPE, RANGE_KEY_SEPARATOR, PLUS_SUFFIX, FACET_LABEL_TYPE, FACET_OPTION_TYPE } from 'Constants/filters';
import { roundOff, formatPrice } from './numbers';
/**
 * returns abundance object with rounded logic and suffix
 * @param {number} count
 * @param {string} locale
 * @return {Object} abundance object
 */
export const getRoundedCount = (count, locale, isCarCategory) => {
    if (!isFinite(count)) {
        return {};
    }
    const numberCount = Number(count);
    const roundedCount = roundOff(numberCount, null, locale);
    let suffixLabelTranslation = numberCount === 1 ? 'buyPkgViewCartItem' : 'buyPkgViewCartItems';

    if (isCarCategory) {
        suffixLabelTranslation = numberCount === 1 ? 'filterCarCountSuffix' : 'filterCarsCountSuffix';
    }

    return {
        count: numberCount,
        formattedExactCount: formatPrice(numberCount, locale),
        roundedCount,
        formattedRoundedCount: formatPrice(roundedCount, locale),
        suffix: numberCount > roundedCount ? PLUS_SUFFIX : '',
        suffixLabelTranslation
    };
};
/**
 * Returns if the componentType is range filter or not
 * @param {COMPONENT_TYPE} componentType
 * @return {boolean}
 */
export const isRangeFilter = componentType => [COMPONENT_TYPE.RANGE_INPUT, COMPONENT_TYPE.RANGE_SLIDER].includes(componentType);
/**
 * Sort Array by Abundance Count
 * @param {Array} arr
 * @param {Object} info
 * @return {Array}
 */
export const sortByAbundance = (arr, info, hideZeroAbundanceOpts) => {
    if (hideZeroAbundanceOpts) {
        arr = [...arr].filter(option => !!getNestedValue(info[option], 'abundance.count', 0));
    }

    return [...arr].sort((first, second) => {
        const firstAbundance = getNestedValue(info[first], 'abundance.count', 0);
        const secondAbundance = getNestedValue(info[second], 'abundance.count', 0);

        return secondAbundance - firstAbundance;
    });
};
/**
 * Returns Composite Key for Range Filters
 * @param {Object} option
 * @returns {string}
 */
export const getRangeKey = option => {
    const isRangeValid = isObject(option) && isObject(option.range);

    if (isRangeValid) {
        const { minValue, maxValue } = option.range;
        const min = isFinite(minValue) && minValue !== null ? Number(minValue) : '';
        const max = isFinite(maxValue) && maxValue !== null ? Number(maxValue) : '';
        const separator = min !== '' && max !== '' ? RANGE_KEY_SEPARATOR : '';

        return `${min}${separator}${max}`;
    }
    return '';
};

/**
 * Merge Abundance Options with Filter Config Options
 * @param {Object} abundanceOptions
 * @param {Array} filterOptions
 * @param {COMPONENT_TYPE} componentType
 * @param {string} locale
 * @return {Array} Array of filter config options
 */
export const mergeAbundance = (abundanceOptions, filterOptions, componentType, locale, hideZeroAbundanceOpts, isCarCategory) => {
    if (!(isObject(abundanceOptions) && Array.isArray(filterOptions))) {
        return filterOptions;
    }

    if (hideZeroAbundanceOpts) {
        filterOptions = filterOptions.filter(option => {
            const key = isRangeFilter(componentType) ? getRangeKey(option) : getNestedValue(option, 'value', '');

            return !!getNestedValue(abundanceOptions[key], 'count', 0);
        });
    }

    return filterOptions.map(option => {
        const key = isRangeFilter(componentType) ? getRangeKey(option) : getNestedValue(option, 'value', '');

        if (isObject(abundanceOptions[key])) {
            return {
                ...option, abundance: getRoundedCount(getNestedValue(abundanceOptions[key], 'count', 0), locale, isCarCategory)
            };
        }
        return { ...option };
    });
};

/**
 * Merge Abundance Options with Filter Config Options for Nested Filters(Brand/Model)
 * @param {Object} abundanceOptions
 * @param {Array} filterOptionsKeys
 * @param {Object} filterOptions
 * @param {Object} modelFilterAbundanceOpts
 * @param {string} locale
 * @return {Object} Object of filter config options
 */
export const mergeMapWithAbundance = (abundanceOptions, filterOptionsKeys, filterOptions, modelFilterAbundanceOpts, locale, hideZeroAbundanceOpts, isCarCategory) => {
    if (!(isObject(abundanceOptions) && isObject(filterOptions) && Array.isArray(filterOptionsKeys))) {
        return filterOptions;
    }
    return filterOptionsKeys.reduce((mergedOptions, optionKey) => {
        let cumulativeFilterOptions = {
            [optionKey]: { ...filterOptions[optionKey] }
        };

        if (isObject(abundanceOptions[optionKey])) {
            cumulativeFilterOptions[optionKey].abundance = getRoundedCount(getNestedValue(abundanceOptions[optionKey], 'count', 0), locale, isCarCategory);

            if (hideZeroAbundanceOpts) {
                cumulativeFilterOptions[optionKey].nestedValues = filterOptions[optionKey].nestedValues.filter(key => !!modelFilterAbundanceOpts?.[key]?.count);
            }
        }
        else if (hideZeroAbundanceOpts) {
            cumulativeFilterOptions = {};
        }

        return { ...mergedOptions, ...cumulativeFilterOptions };
    }, {});
};

/**
 * Return Merged Popular Configuration with Abundance
 * @param {Object} abundancePopularConfig
 * @param {Object} popularConfig
 * @param {COMPONENT_TYPE} componentType
 * @param {string} locale
 * @param {boolean} hideZeroAbundanceOpts
 * @param {boolean} isCarCategory
 * @param {boolean} shouldOptimizedFilterFacet
 * @returns {Object}
 */
export const getMergedPopularConfig = (abundancePopularConfig, popularConfig, componentType, locale, hideZeroAbundanceOpts, isCarCategory, shouldOptimizedFilterFacet) => {
    const { options: filterOptions, ...restPopularConfig } = popularConfig;
    const optionsMapKey = isRangeFilter(componentType) ? ['range.min', 'range.max'] : 'value';
    const abundanceOptions = arrayToObject(abundancePopularConfig.values, optionsMapKey, RANGE_KEY_SEPARATOR);
    const extraData = {};

    const optionsWithAbundance = mergeAbundance(abundanceOptions, filterOptions, componentType, locale, hideZeroAbundanceOpts, isCarCategory);

    if (shouldOptimizedFilterFacet) {
        extraData.renderOptionAs = FACET_OPTION_TYPE;
    }

    if (hideZeroAbundanceOpts && !optionsWithAbundance?.length) {
        return null;
    }

    return {
        options: optionsWithAbundance,
        ...restPopularConfig,
        ...extraData
    };
};

/**
 * Return Merged Custom Configuration with Abundance
 * @param {Array} abundanceCustomConfig
 * @param {Object} customConfig
 * @param {COMPONENT_TYPE} componentType
 * @param {string} locale
 * @param {boolean} hideZeroAbundanceOpts
 * @param {boolean} isCarCategory
 * @param {boolean} shouldOptimizedFilterFacet
 * @returns {Object}
 */
export const getMergedCustomConfig = (abundanceFilterInfo, filterAttribute, customConfig, componentType, locale, hideZeroAbundanceOpts, isCarCategory, shouldOptimizedFilterFacet) => {
    const abundanceCustomConfig = abundanceFilterInfo[filterAttribute].values;
    const { values, valuesInfo, ...restCustomConfig } = customConfig;
    const abundanceOptions = arrayToObject(abundanceCustomConfig, 'id');
    const extraData = {};

    // For Brand/Model
    if (isObject(valuesInfo)) {
        const modelValues = abundanceFilterInfo?.model?.values || abundanceFilterInfo?.m_tipe?.values; // In ID, we use m_tipe
        const modelFilterAbundanceOpts = arrayToObject(modelValues, 'id');
        const mergedInfo = mergeMapWithAbundance(abundanceOptions, values, valuesInfo, modelFilterAbundanceOpts, locale, hideZeroAbundanceOpts, isCarCategory);
        const mergedValues = sortByAbundance(values, mergedInfo, hideZeroAbundanceOpts);

        return {
            ...restCustomConfig,
            values: mergedValues,
            valuesInfo: mergedInfo
        };
    }

    if (isRangeFilter(componentType) && isObject(abundanceOptions)) {
        const keys = Object.keys(abundanceOptions);

        if (keys.length) {
            extraData.histogramData = keys.map(key => abundanceOptions[key].count);
        }
    }

    if (shouldOptimizedFilterFacet) {
        extraData.renderLabelAs = FACET_LABEL_TYPE;
    }

    return {
        ...restCustomConfig,
        values: mergeAbundance(abundanceOptions, values, componentType, locale, hideZeroAbundanceOpts, isCarCategory),
        ...extraData
    };
};

/**
 * Converts Object of Filter into Phoenix Params
 * @param {Object} filters
 * @returns {Object}
 */
export const decodeFilterObj = (filters = {}) => {
    return Object.keys(filters).reduce((acc, key) => {
        const value = filters[key];

        if (Array.isArray(value)) {
            // Select Filters
            return { ...acc, [key]: value.join(',') };
        }
        else if (isObject(value) && (value.min || value.max)) {
            const newRangeObj = {};

            // Range Filters
            if (value.min) {
                newRangeObj[`${key}_min`] = value.min;
            }
            if (value.max) {
                newRangeObj[`${key}_max`] = value.max;
            }
            return { ...acc, ...newRangeObj };
        }
        return acc;
    }, {});
};
