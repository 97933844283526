import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';

import ExpansionPanel from 'Components/ExpansionPanel/ExpansionPanel';
import withSearches from 'HOCs/withSearches/withSearches';
import MobileLinksSearch from 'Components/Listing/components/PopularSearches/MobileLinksSearch';
import css from 'Components/Listing/components/PopularSearches/PopularSearches.scss';

const popularSearchesTitle = <Translation id="relatedSearches" />;

const BottomPopularSearches = ({ searches }) => {
    if (!searches || !searches.length) {
        return null;
    }

    return (
        <ExpansionPanel
            containerClassName={ css.mobilePopularSearches }
            title={ popularSearchesTitle }
            id="popular-searches-mobile"
        >
            <ul>
                <MobileLinksSearch searches={ searches } />
            </ul>
        </ExpansionPanel>
    );
};

BottomPopularSearches.propTypes = {
    searches: PropTypes.array
};

BottomPopularSearches.defaultProps = {
    searches: []
};

export default withSearches(BottomPopularSearches);
