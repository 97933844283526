import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './Tabs.APP_TARGET.scss';
import useScrollDirection, {
    SCROLL_DOWN,
    SCROLL_TOP,
    SCROLL_UP
} from 'Hooks/useScrollDirection/useScrollDirection';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import TabsUI from './TabsUI';
import { FormattedMessage as Translation } from 'react-intl';

const HEADER_SCROLL_UP_TRANSITION_THRESHOLD = 50;
const HEADER_SCROLL_DOWN_TRANSITION_THRESHOLD = 100;

export const Tabs = ({
    tabsData,
    tabName,
    onTabClick,
    isMxCl,
    subHeader,
    headerLogo,
    header
}) => {
    const { scrollDirection, currentScrollY } = useScrollDirection({});

    const showSubHeader = useCallback(() => {
        if (header && subHeader) {
            subHeader.style.top = `${header.offsetHeight}px`;
            subHeader.classList.remove(css.hidden);
            if (!subHeader.classList.contains(css.stickyTransition)) {
                subHeader.classList.add(css.stickyTransition);
            }
            header.classList.remove(css.shadow);
        }
    }, [header, subHeader]);

    const handleHeaderOnScroll = useCallback(() => {
        if (isMxCl && !IS_DESKTOP && headerLogo && header && subHeader) {
            const menuLocation = document.querySelector('#menuLocation');

            switch (scrollDirection) {
                case SCROLL_TOP:
                case SCROLL_UP:
                    if (
                        currentScrollY < HEADER_SCROLL_UP_TRANSITION_THRESHOLD
                    ) {
                        if (menuLocation) {
                            menuLocation.classList.remove(css.shrinkHide);
                        }
                        headerLogo.classList.remove(css.shrinkHide);
                        header.classList.remove(css.flexRow);
                    }
                    showSubHeader();
                    break;
                case SCROLL_DOWN:
                    if (
                        currentScrollY > HEADER_SCROLL_DOWN_TRANSITION_THRESHOLD
                    ) {
                        if (menuLocation) {
                            menuLocation.classList.add(css.shrinkHide);
                        }
                        headerLogo.classList.add(css.shrinkHide);
                        subHeader.classList.add(css.hidden);
                        if (subHeader.children) {
                            subHeader.children[0].classList.add(css.hide);
                        }
                        header.classList.add(css.flexRow, css.shadow);
                    }
                    break;
            }
        }
    }, [
        scrollDirection,
        subHeader,
        isMxCl,
        showSubHeader,
        headerLogo,
        header,
        currentScrollY
    ]);

    useEffect(() => {
        handleHeaderOnScroll();
    }, [handleHeaderOnScroll]);

    let modifiedTabsData = null;

    if (tabsData?.length > 0) {
        modifiedTabsData = tabsData.map(tab => ({
            ...tab,
            title: (
                <Translation
                    id={ tab.title }
                    defaultMessage={ tab.defaultMessage }
                />
            )
        }));
    }

    return (
        modifiedTabsData && (
            <TabsUI
                tabsData={ modifiedTabsData }
                tabName={ tabName }
                onTabClick={ onTabClick }
            />
        )
    );
};

Tabs.propTypes = {
    tabsData: PropTypes.arrayOf(PropTypes.object),
    tabName: PropTypes.string,
    onTabClick: PropTypes.func,
    isMxCl: PropTypes.bool,
    subHeader: PropTypes.object,
    headerLogo: PropTypes.object,
    header: PropTypes.object
};

Tabs.defaultProps = {
    tabsData: []
};

export default React.memo(Tabs);
