import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import experimentMap from './experimentMap';

export const withMultipleExperiments = expIds => WrappedComponent => {
    const Component = props => {
        const experiments = expIds.reduce((prev, curr) => ({ ...prev, [curr]: experimentMap[curr] || experimentMap.defaultExpVariant }), {});

        return (
            <WrappedComponent
                { ...props }
                experiments={ experiments }
            />
        );
    };

    Component.displayName = `withMultipleExperiments(${WrappedComponent.displayName || WrappedComponent.name})`;

    return hoistNonReactStatic(Component, WrappedComponent);
};

export default withMultipleExperiments;
