/**
 * Different vehicle parameter keys for different markets as they are different from the default ones for (at least) Indonesia.
 * @type {{'www.olxautos.co.id': {transmission: string, color: string, year: string, fuel: string, variant: string, body_type: string, mileage: string}}}
 */
const MarketsVehicleParameters = {
    'www.olxautos.co.id': {
        variant: 'm_tipe_variant',
        year: 'm_year',
        transmission: 'm_transmission',
        body_type: 'm_body',
        color: 'm_color',
        fuel: 'm_fuel',
        mileage: 'mileage'
    }
};

/**
 * Get vehicle parameters for different markets in case they are different from the default ones.
 * @param host - Hostname of the current market.
 * @param parameters
 * @returns {{bodyType: *, color: *, fuelType: *, year: *, variant: *, vehicleTransmission: *, mileage: *}}
 */
const getVehicleParameters = (host, parameters) => {
    const variant = parameters.find(parameter => parameter.key === 'variant' || parameter.key === MarketsVehicleParameters?.[host]?.variant)?.value;
    const year = parameters.find(parameter => parameter.key === 'year' || parameter.key === MarketsVehicleParameters?.[host]?.year)?.value;
    const vehicleTransmission = parameters.find(parameter => parameter.key === 'transmission' || parameter.key === MarketsVehicleParameters?.[host]?.transmission)?.value;
    const bodyType = parameters.find(parameter => parameter.key === 'body_type' || parameter.key === MarketsVehicleParameters?.[host]?.body_type)?.value;
    const color = parameters.find(parameter => parameter.key === 'color' || parameter.key === MarketsVehicleParameters?.[host]?.color)?.value;
    const fuelType = parameters.find(parameter => parameter.key === 'fuel' || parameter.key === MarketsVehicleParameters?.[host]?.fuel)?.value;
    const mileage = parameters.find(parameter => parameter.key === 'km_driven')?.value ?? parameters.find(parameter => parameter.key === 'mileage' || parameter.key === MarketsVehicleParameters?.[host]?.mileage)?.value;

    return { variant, year, vehicleTransmission, bodyType, color, fuelType, mileage };
};

/**
 * Build the JSON LD schema for a vehicle item.
 * @param props
 * @returns {{mainEntity: {bodyType: *, offers: {image: *, priceCurrency: *, '@type': string, price: *, availabilityStarts: *}, vehicleConfiguration: *, color: *, vehicleEngine: [{fuelType: *, '@type': string}], '@type': string, modelDate: *, mileageFromOdometer: {'@type': string, unitCode: string, value: *}, name, steeringPosition: (string), vehicleTransmission: *}, breadcrumb: {itemListElement, '@type': string, numberOfItems: string}, '@type': string, name, description, inLanguage, '@context': string, url: string}}
 */
const buildProduct = props => {
    const { item, app, breadcrumbLinks, location, config, allItemImages } = props;
    const { title, description } = item;
    const host = app.location.hostname;
    const protocol = config.location.country.protocol;
    const vehicleParameters = item.parameters;
    const url = `${protocol}://${host}${location.pathname}`;
    const { variant, year, vehicleTransmission, bodyType, color, fuelType, mileage } = getVehicleParameters(host, vehicleParameters);
    const siteCode = config.location.country.site_code;
    const steeringPosition = siteCode === 'olxin' ? 'https://schema.org/RightHandDriving' : 'https://schema.org/LeftHandDriving';
    const price = item.price.value.raw;
    const priceCurrency = item.price.value.currency.iso_4217;
    const image = allItemImages[0].url;
    const inspectionDate = item.inspectionDetails.additionalInfo.parameters.find(parameter => parameter.key === 'inspectionDate')?.value;

    const getItemListElement = links => links.slice(0, -1).map((link, index) => {
        const name = link.text || link.translation?.values?.category || link.translation?.id;

        return {
            '@type': 'ListItem',
            position: index,
            item: {
                '@id': `${protocol}://${host}${link.href}`,
                name
            }
        };
    });

    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: title,
        description,
        url,
        inLanguage: app.lang,
        breadcrumb: {
            '@type': 'BreadcrumbList',
            numberOfItems: '4',
            itemListElement: getItemListElement(breadcrumbLinks)
        },
        mainEntity: {
            '@type': 'Car',
            name: title,
            vehicleConfiguration: variant,
            modelDate: year,
            bodyType,
            steeringPosition,
            color,
            vehicleTransmission,
            vehicleEngine: [
                {
                    '@type': 'EngineSpecification',
                    fuelType
                }
            ],
            mileageFromOdometer: {
                '@type': 'QuantitativeValue',
                value: mileage,
                unitCode: 'KMT'
            },
            offers: {
                '@type': 'Offer',
                price,
                priceCurrency,
                availabilityStarts: inspectionDate,
                image

            }
        }
    };
};

export default buildProduct;
