/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Button from 'Components/Button/Button';
import { FIELD_NAME, LEAD_GEN_SOURCES } from 'Constants/items';
import Email from 'Components/Fields/Email/Email';
import Phone from 'Components/Fields/Phone/Phone';
import TnCCheckbox from 'Components/ExponeaLeadUI/TermsAndConditionsCheckbox/TermsAndConditionsCheckboxAsync';
import ValidatedNewTextField from 'Components/Fields/Validated/ValidatedNewTextField';
import ThankYouWidget from 'Components/ThankYouWidget/ThankYouWidget';
import ErrorMessage from '../../../pages/Item/adpvMXComponents/ErrorMessage/ErrorMessage';
import { isMobile } from 'Helpers/devices';
import { noop } from 'Helpers/function';
import classNames from 'classnames';

export const UserDetailsFormUI = ({
    css,
    showThankYou,
    onLeadSubmit,
    intl,
    name,
    setName,
    isPosting,
    setEmail,
    areAllUserValuesValid,
    config,
    setPhone,
    isErr,
    thankYouTitle,
    thankYouSubtitle,
    showSubHeading,
    subHeading,
    showTAndC,
    onLinkTap,
    showTnCCheckBox = false,
    tncAccepted = true,
    onToggleTnC = noop,
    handleFieldChange,
    hp_tracking_event
}) => {
    const handleOnBlur = useCallback(ev => {
        if (ev.target.value) {
            const trackingData = {
                field_name: ev.target.name,
                chosen_option: ev.target.value,
                select_from: hp_tracking_event,
                flow_type: LEAD_GEN_SOURCES.EXCHANGE_CAR
            };

            handleFieldChange(trackingData);
        }
    }, [handleFieldChange, hp_tracking_event]);

    return (
        <>
            { !showThankYou && (
                <form
                    className={ css.userDetailsForm }
                    onSubmit={ onLeadSubmit }
                >
                    {showSubHeading
                        && (typeof subHeading === 'string' ? (<div className={ css.userDetailsForm__heading }>{ subHeading || intl.formatMessage({ id: 'leadGenModalHeading' }) }</div>) : subHeading)
                    }
                    <ValidatedNewTextField
                        id="user_details_name"
                        name={ FIELD_NAME.NAME }
                        className={ cx(css.userDetailsForm__fields, css.nameField) }
                        label={ `${intl.formatMessage({ id: 'nameLabel' })} *` }
                        placeholder={ intl.formatMessage({ id: 'namePlaceholder' }) }
                        value={ name.val }
                        onChange={ setName }
                        validateOnBlur={ false }
                        validateOnChange
                        requiredErrorMessage={ intl.formatMessage({ id: 'nameRequiredError' }) }
                        required
                        direction={ 'ltr' }
                        disabled={ isPosting }
                        type="text"
                        onBlur={ handleOnBlur }
                    />
                    <Email
                        id="user_details_email"
                        name={ FIELD_NAME.EMAIL }
                        className={ cx(css.userDetailsForm__fields, css.emailField) }
                        label={ `${intl.formatMessage({ id: 'login_email_enter_email_email' })} *` }
                        onChange={ setEmail }
                        requiredErrorMessage={ intl.formatMessage({ id: 'emailRequired' }) }
                        regexErrorMessage={ intl.formatMessage({ id: 'invalidEmail' }) }
                        placeholder={ intl.formatMessage({ id: 'emailPlaceholder' }) }
                        validateOnBlur={ false }
                        validateOnChange
                        required
                        direction={ 'ltr' }
                        autoFocus={ false }
                        disabled={ isPosting }
                        validateOnMount={ false }
                        onBlur={ handleOnBlur }
                    />

                    <Phone
                        id="user_details_phone"
                        name={ FIELD_NAME.PHONE }
                        className={ cx(css.userDetailsForm__fields, css.phoneField) }
                        label={ `${intl.formatMessage({ id: 'login_sms_enter_phone_number' })} *` }
                        type="number"
                        onChange={ setPhone }
                        requiredErrorMessage={ intl.formatMessage({ id: 'mobileRequired' }) }
                        regexErrorMessage={ intl.formatMessage({ id: 'mobileError' }) }
                        placeholder={ intl.formatMessage({ id: 'enterNumber' }) }
                        validateOnBlur={ false }
                        validateOnChange
                        required
                        direction={ 'ltr' }
                        autoFocus={ false }
                        disabled={ isPosting }
                        onBlur={ handleOnBlur }
                        validateOnMount
                    />
                    <div className={ css.userDetailsForm__additionalInfo }>
                        { `* ${intl.formatMessage({ id: 'mandatoryTxt' })}` }
                    </div>
                    { isErr && <ErrorMessage />}
                    { showTnCCheckBox && (
                        <div className={ classNames(css.userDetailsForm__wrapperDiv, {
                            [css.userDetailsForm__tncCheckboxbg]: isMobile
                        }) }
                        >
                            <TnCCheckbox
                                id="userDetails_tncCheckbox"
                                className={ css.userDetailsForm__tncCheckbox }
                                labelvalue={ tncAccepted }
                                labelClassName={ css.userDetailsForm__tncCheckbox__label }
                                onChange={ onToggleTnC }
                            />
                        </div>
                    )}

                    <Button
                        className={ cx(css.userDetailsForm__submitBtn, css.imp) }
                        type="primary"
                        htmlType="submit"
                        disabled={ isPosting || !(areAllUserValuesValid && tncAccepted) }
                        fixed
                    >
                        { intl.formatMessage({ id: 'submit' }) }
                    </Button>
                    {showTAndC
                        ? (<div className={ css.userDetailsForm__disclaimer }>
                            <Link
                                to={ (config.get('staticAssets') + config.get('redirectionTermsAndConditions')).toString() }
                                onClick={ onLinkTap }
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                            >
                                { intl.formatMessage({ id: 'termsConditionsTxt' }) }
                            </Link>&nbsp;{ intl.formatMessage({ id: 'appliedTxt' }) }
                        </div>) : null }
                </form>
            )}
            { showThankYou && (
                <ThankYouWidget
                    title={ thankYouTitle }
                    subTitle={ thankYouSubtitle }
                    className={ css.thankYou }
                    imgClassName={ css.img }
                    textClassName={ css.textWrapper }
                />)
            }
        </>
    );
};

UserDetailsFormUI.propTypes = {
    css: PropTypes.shape({}),
    intl: PropTypes.shape({
        formatMessage: PropTypes.func
    }).isRequired,
    config: PropTypes.shape({
        get: PropTypes.func
    }),
    showThankYou: PropTypes.bool,
    onLeadSubmit: PropTypes.func,
    name: PropTypes.object,
    setName: PropTypes.func,
    isPosting: PropTypes.bool,
    setEmail: PropTypes.func,
    areAllUserValuesValid: PropTypes.bool,
    setPhone: PropTypes.func,
    isErr: PropTypes.bool,
    thankYouTitle: PropTypes.string,
    thankYouSubtitle: PropTypes.string,
    showTAndC: PropTypes.bool,
    showTnCCheckBox: PropTypes.bool,
    tncAccepted: PropTypes.bool,
    onToggleTnC: PropTypes.func,
    handleFieldChange: PropTypes.func,
    hp_tracking_event: PropTypes.string
};

UserDetailsFormUI.defaultProps = {
    onLeadSubmit: noop,
    setName: noop,
    setEmail: noop,
    setPhone: noop,
    css: {},
    showTAndC: true,
    onLinkTap: noop
};

export default React.memo(UserDetailsFormUI);
