import { LOGIN_GRANT_TYPE } from 'Constants/authentication';

export const API_NAME = {
    AUTHENTICATE: 'AUTHENTICATE',
    LOGIN: 'LOGIN',
    SEND_OTP: 'SEND_OTP',
    VALIDATE_OTP: 'VALIDATE_OTP',
    LINK_PHONE: 'LINK_PHONE',
    GET_USER_DETAILS: 'GET_USER_DETAILS',
    RESEND_OTP: 'RESEND_OTP'
};

export const SUCCESS_WAIT_INTERVAL = 3000;

export const FLOW_TYPE = {
    LOGIN_REGISTER: 'LOGIN_REGISTER',
    LINK_PHONE: 'LINK_PHONE'
};

export const ERROR_TYPE = {
    USER_BANNED: 'user_banned'
};

export const TRACKING_PARAMS = {
    REGISTER: 'register',
    LOGIN: 'login',
    AUTO_OTP: 'auto_otp',
    MANUAL: 'manual',
    PIN_SCREEN: 'pin_screen',
    BROWSER_DETAILS: 'browser_details',
    FIND_USER_SCREEN: 'find_user_screen'
};

export const trackedLoginMethods = [
    LOGIN_GRANT_TYPE.email,
    LOGIN_GRANT_TYPE.phone,
    LOGIN_GRANT_TYPE.recover
];
