import { PLATFORM as APP_TARGET } from 'Constants/device.APP_TARGET';

const ENV = {
    PROD: 'production',
    STG: 'staging',
    DEV: 'development'
};

const CF_VERSION = {
    PROD: 'prd',
    STG: 'stg',
    DEV: 'local',
    INTEGRATION: 'stg'
};

const URL = {
    RESOURCE: 'remoteEntry.js'
};

const resolveVersion = ({ environment, cfBundleName: cfVersion }) => {
    if (environment === ENV.PROD) {                                                                      // for production
        return CF_VERSION.PROD;
    }
    return cfVersion || CF_VERSION.STG;
};

const getUrlForRemoteChunk = (host, target) => {
    const { RESOURCE } = URL;

    // ?v= for cache busting // cache control headers can be used
    return `${host}${target}${RESOURCE}?v=${Date.now()}`;
};

export const getFinanceStaticUrl = ({ app = {}, config }) => {
    const { envBundleName, environment, cfBundleName } = app;
    const version = resolveVersion({ environment, cfBundleName });
    const staticAssetsUrl = config.get('staticAssets');
    const moduleSystem = `${envBundleName === 'modern' ? 'es' : 'cjs'}`;

    if (version === 'local') {
        return getUrlForRemoteChunk('http://localhost:9000', '/');
    }

    // path consists of 3 parts `${CF-VERSION}/${MODULE-SYSTEM}/${PLATFORM}`
    const path = `/remotes/consumer-finance/${version}/${moduleSystem}/${APP_TARGET}/`;

    return getUrlForRemoteChunk(staticAssetsUrl, path);
};
