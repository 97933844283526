import { useState, useCallback, useMemo } from 'react';
import { useItemProps } from 'Hooks/useItemProps';
import { useDealerProps } from 'Hooks/useDealerProps';
import { isSuccess, isError } from 'Helpers/http';
import { omitBy } from 'Helpers/objects';
import { formatDate } from 'Helpers/dates';

// using for stupid IE browsers (-_- ")
import fetchFactory from 'fetch-ponyfill';

const { fetch } = fetchFactory();
const omitUndefinedKeys = obj => omitBy(obj, val => typeof val === 'undefined');

export const useLeadGeneration = ({
    eventName,
    item,
    dealer,
    sellCar,
    userFlow,
    siteCode,
    hitchAmount,
    installmentPeriod,
    onComplete,
    optionalParams = {
        lastName: false,
        motherMaidenName: false,
        birthDate: false
    }
}) => {
    const [name, setName] = useState({ val: '', isValid: false, isInUse: true });
    const [lastName, setLastName] = useState({ val: '', isValid: false, isInUse: optionalParams.lastName });
    const [motherMaidenName, setMotherMaidenName] = useState({ val: '', isValid: false, isInUse: optionalParams.motherMaidenName });
    const [email, setEmail] = useState({ val: '', isValid: false, isInUse: true });
    const [phone, setPhone] = useState({ val: '', isValid: false, isInUse: true });
    const [birthDate, setBirthDate] = useState({ val: '', isValid: false, isInUse: optionalParams.birthDate });

    const [isPosting, setPostingStatus] = useState(false);
    const [isErr, setErrStatus] = useState(false);
    const [isCompleted, setCompletedStatus] = useState(false);

    const onEmailChange = useCallback((val, _, isValid) => {
        setEmail({ ...email, val, isValid });
    }, [email]);

    const onNameChange = useCallback((val, _, isValid) => {
        setName({ ...name, val, isValid });
    }, [name]);

    const onPhoneChange = useCallback((val, _, isValid) => {
        setPhone({ ...phone, val, isValid });
    }, [phone]);

    const onLastNameChange = useCallback((val, _, isValid) => {
        setLastName({ ...lastName, val, isValid });
    }, [lastName]);

    const onMotherMaidenNameChange = useCallback((val, _, isValid) => {
        setMotherMaidenName({ ...motherMaidenName, val, isValid });
    }, [motherMaidenName]);

    const onBirthDateChange = useCallback((val, _, isValid) => {
        setBirthDate({ ...birthDate, val, isValid });
    }, [birthDate]);

    const isValidInputs = useCallback((inputs = []) => {
        return inputs.every(({ isInUse, isValid }) => (isInUse ? isValid : true));
    }, []);

    const areAllValuesValid = isValidInputs([email, name, phone, motherMaidenName, lastName, birthDate]);

    const itemInfo = useItemProps(item);
    const dealerInfo = useDealerProps(dealer);

    const sellCarInfo = useMemo(() => {
        if (!sellCar) {
            return {};
        }
        const sellCarObj = {
            sellModel: sellCar.model,
            sellMake: sellCar.make
        };

        return omitUndefinedKeys(sellCarObj);
    }, [sellCar]);

    const financeInfo = useMemo(() => {
        const financeInfoObj = {
            downPayment: hitchAmount || undefined,
            selectedInstallment: installmentPeriod || undefined
        };

        return omitUndefinedKeys(financeInfoObj);
    }, [hitchAmount, installmentPeriod]);

    const onLeadSubmit = useCallback(async evt => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        setPostingStatus(true);
        setErrStatus(false);

        const params = {
            userPhone: phone.val,
            userName: name.val,
            userEmail: email.val,
            ...lastName.val && { userLastName: lastName.val },
            ...motherMaidenName.val && { motherLastName: motherMaidenName.val },
            ...birthDate.val && { userBirth: formatDate(new Date(birthDate.val), 'YYYY-MM-DD') },
            userFlow,
            ...itemInfo,
            ...dealerInfo,
            ...sellCarInfo,
            ...financeInfo
        };

        let olxUserId = null;

        try {
            const resp = await fetch(`/api/bxp/leadData?eventType=${eventName}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-panamera-sitecode': siteCode
                },
                body: JSON.stringify(params)
            });

            if (isSuccess(resp.status)) {
                const responseBody = await resp.json();
                const { data } = responseBody || {};
                const { userId } = data || {};

                if (userId) {
                    olxUserId = userId;
                }
            }

            if (isError(resp.status)) {
                setErrStatus(true);
            }
        }
        finally {
            setCompletedStatus(true);
            setPostingStatus(false);
            if (onComplete) {
                onComplete({ params, uid: olxUserId });
            }
        }
        return false;
    }, [birthDate.val, dealerInfo, email.val, eventName, financeInfo, itemInfo, lastName.val, motherMaidenName.val, name.val, onComplete, phone.val, sellCarInfo, siteCode, userFlow]);

    return [
        [name, onNameChange],
        [email, onEmailChange],
        [phone, onPhoneChange],
        areAllValuesValid,
        isPosting,
        isCompleted,
        onLeadSubmit,
        isErr,
        [lastName, onLastNameChange],
        [motherMaidenName, onMotherMaidenNameChange],
        [birthDate, onBirthDateChange]
    ];
};
