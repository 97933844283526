import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import CookieManager from 'Helpers/cookies';
import { withConfig } from 'HOCs/withConfig/withConfig';

/**
 * Unified data fetching solution (client & SSR)
 *
 * @param actionCreator [Function] - This function should be used to dispatch all necessary actions to fetch data
 *   (dispatch, { location, params, cookies, config }) => Promise
 * @param mapStateToProps [Function / Object] - It will passed to redux's connect
 */
const withDataFetching = (actionCreator, mapStateToProps = null) => WrappedComponent => {
    class WithDataFetching extends React.Component {
        static fetchData = (...args) => {
            const [dispatch, renderProps, reqProps, reqContext] = args;
            const { location, params } = renderProps;
            const { cookies } = reqProps;
            const { config } = reqContext;

            const wrappedComponentPromise = WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve();

            return Promise.all([
                wrappedComponentPromise,
                // third parameter helps us differentiate network calls for client and server side.See Home.jsx actionCreator
                actionCreator(dispatch, { location, params, cookies, config }, {
                    isSSR: true,
                    wrappedComponentPromise
                })
            ]);
        }

        componentDidMount() {
            const { dispatch, location, params, staticConfig } = this.props;
            const cookies = CookieManager.getAllCookies();
            const config = staticConfig.get();

            actionCreator(dispatch, { location, params, cookies, config });
        }

        componentDidUpdate() {
            const { dispatch, location, params, staticConfig } = this.props;
            const cookies = CookieManager.getAllCookies();
            const config = staticConfig.get();

            actionCreator(dispatch, { location, params, cookies, config });
        }

        render() {
            const { staticConfig, ...props } = this.props; // eslint-disable-line no-unused-vars

            return (
                <WrappedComponent { ...props } />
            );
        }
    }

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithDataFetching.displayName = `withDataFetching(${wrappedComponentName})`;
    WithDataFetching.propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        params: PropTypes.object.isRequired,
        staticConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired
    };

    const connectedComponent = compose(
        connect(mapStateToProps),
        withConfig('staticConfig')
    )(WithDataFetching);

    return hoistNonReactStatic(connectedComponent, WrappedComponent, { fetchData: true });
};

export default withDataFetching;
