/* global window */

import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';
export const SCROLL_TOP = 'top';
const DEFAULT_THRESHOLD_PIXEL = 15;
const DEFAULT_SCROLL_DEBOUNCE_THRESHOLD = 100;

export const useScrollDirection = ({
    thresholdPixel = DEFAULT_THRESHOLD_PIXEL,
    scrollDebounceThreshold = DEFAULT_SCROLL_DEBOUNCE_THRESHOLD,
    isDebounced = false
}) => {
    const [scrollDirection, setScrollDirection] = useState(SCROLL_TOP);
    const [prevOffset, setPrevOffset] = useState(0);

    const wrapperFn = isDebounced ? debounce : fn => fn;

    const toggleScroll = wrapperFn(() => {
        const scrollY = window.scrollY;
        const scrollDiff = scrollY - prevOffset;

        if (scrollY === 0) {
            setScrollDirection(SCROLL_TOP);
            setPrevOffset(scrollY);
            return;
        }
        if (Math.abs(scrollDiff) > thresholdPixel) {
            if (scrollDiff > thresholdPixel) {
                setScrollDirection(SCROLL_DOWN);
            }
            else if (scrollDiff < -thresholdPixel) {
                setScrollDirection(SCROLL_UP);
            }
            setPrevOffset(scrollY);
        }
    }, scrollDebounceThreshold);

    const toggleScrollDirection = useCallback(
        toggleScroll,
        [prevOffset, thresholdPixel, toggleScroll]
    );

    useEffect(() => {
        window.addEventListener('scroll', toggleScrollDirection);
        return () => {
            window.removeEventListener('scroll', toggleScrollDirection);
        };
    }, [toggleScrollDirection]);

    return { scrollDirection, currentScrollY: prevOffset };
};

export default useScrollDirection;
